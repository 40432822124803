import { type FC, useState } from 'react'
import { GetCurrencyByString, displayTidyCurrency, displayTidyWithoutCurrency, getCurrencySymbol, penniesToPound } from '@lib/services'
import CurrencyInput from 'react-currency-input-field'
import { Button } from '@lib/components'
import rightArrow from '../assets/icons/rightArrow.svg'
import { GivematchTestId } from '@givematch/common'
import { maxDonationByCurrency } from '~/service/maximumDonationAmounts'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import logoIcon from '../assets/logo_icon_dark.svg'
import { useNavigate, Link } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'
import { shouldUseTempFeature, TempCharityFeature } from './fundraiser/temp-charity-feature/TempCharityFeature'

interface props {
  onButtonClick: (amount: number) => void
  donateButton: boolean
  charityId?: string
  charityName?: string
  logoImage?: string
  showFundraiserButton?: boolean
  notAcceptingDonation?: boolean // this attribute will deactivate the button if it is in preview or the fundraiser is not accepting donations
}

const DonationCard: FC<props> = ({ onButtonClick, donateButton, charityId, charityName, logoImage, notAcceptingDonation, showFundraiserButton = true }) => {
  const navigate = useNavigate()
  const { donation, setDonation } = useDonationPlan()
  const [logoExists, setLogoExists] = useState(!!logoImage)
  const maxDonationMatch = maxDonationByCurrency(donation.donationCurrency)
  const shouldUseTemp = shouldUseTempFeature(charityId)
  const onValueChange = (value?: string): void => {
    const donationAmount = parseFloat(value ?? '0') * 100
    if (shouldUseTemp || donationAmount <= maxDonationMatch) {
      setDonation({ ...donation, amount: donationAmount, matchedAmount: donationAmount })
    } else {
      setDonation({ ...donation, amount: donationAmount, matchedAmount: maxDonationMatch })
    }
  }

  const startFundraiser = (): void => {
    Tracking.startYourFundraiser()
    navigate(Navigation.fundraiserCreate(true))
  }
  const disableButton = donation.amount < 100 || notAcceptingDonation

  return <div>
    <div
      className={`flex flex-col ${!charityName ? 'gap-y-5' : ''} ${logoExists ? 'px-6 pb-6 pt-2' : 'p-6'} max-w-sm font-bold text-left rounded-xl bg-white border-4 border-gm-green donationCard shadow-md`}
    >
      {donateButton && (
        <div className='flex mb-2 text-gm-green text-2xl tracking-tighter justify-between'>
          YOU GIVE, WE MATCH
        </div>
      )}
      {!donateButton && charityName && charityId && (
        <div className="flex justify-end items-center gap-2">
          {!!logoImage && logoExists && (
            <img
              className="rounded-lg align-middle ml-1 w-20 h-20 object-contain"
              src={`/imageuploads/charity/${charityId}/${logoImage}`}
              alt={`${charityName} logo`}
              onError={() => setLogoExists(false)}
            />
          )}
          {!logoExists && (
            <span className="text-white p-1 bg-gm-green border-2 border-gm-green rounded">
              {charityName}
            </span>
          )}
        </div>
      )}
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col">
          <label
            className="text-left text-gm-green-500"
            htmlFor='donation-amount'
          >
            YOU DONATE
          </label>
          <div className='flex px-2 border-2 rounded-md border-gm-green'>
            <span className='w-2/6 flex items-center text-5xl font-semibold text-right text-gm-green focus:outline-none'>
              {getCurrencySymbol(GetCurrencyByString(donation.donationCurrency))}
            </span>
            <CurrencyInput
              defaultValue={penniesToPound(donation.amount) ?? ''}
              data-test-id={GivematchTestId.donationInput}
              id="donation-amount"
              name="donation-amount"
              className="w-4/6 text-5xl font-semibold text-right text-gm-green focus:outline-none border-gm-green"
              placeholder='.00'
              step={0.01}
              onValueChange={onValueChange}
              autoFocus
              readOnly={notAcceptingDonation}
            />
          </div>
          <p className="text-left text-gm-green-500 text-xs font-light">Enter your donation amount</p>
        </div>
        <div>
          <p className="text-left">
            <TempCharityFeature
              usual={'WE DONATE'}
              temp={'VITOL WILL DONATE'}
              charityId={charityId}
            />
          </p>
          <div className="w-full border-2 rounded-md flex justify-between text-5xl text-left px-2 py-3 font-semibold overflow-hidden">
            <span>{getCurrencySymbol(GetCurrencyByString(donation.donationCurrency))}</span>
            <span>{displayTidyWithoutCurrency(donation.matchedAmount)}</span>
          </div>
          <p className="text-left text-xs font-light">
            Match Fund delivered by{' '}
            <TempCharityFeature
              usual={
                <Link
                  target='_blank'
                  rel='noreferrer'
                  className='text-gm-green cursor-pointer'
                  to={Navigation.matchFundPartners()}
                >
                  Partner
                </Link>
              }
              temp={
                <a
                  target='_blank'
                  rel='noreferrer'
                  className='text-gm-green cursor-pointer'
                  href='https://www.vitol.com'
                >
                  Vitol
                </a>
              }
              charityId={charityId}
            />
          </p>
        </div>
        {!shouldUseTemp && donation.matchedAmount === maxDonationMatch && (
          <div className='text-xs font-light'>
            *We match your first {displayTidyCurrency(maxDonationMatch, donation.donationCurrency)}
          </div>
        )}
        <Button
          className={`w-full rounded-full h-14 ${disableButton ? ' cursor-not-allowed bg-sz-gray-500  active:bg-sz-gray-500 text-gm-black' : ''}`}
          variant={!donateButton ? 'gm-primary' : disableButton ? 'gm-primary-md' : 'gm-share'}
          data-test-id={GivematchTestId.donateButton}
          disabled={ disableButton }
          onClick={() => onButtonClick(donation.amount)}>
          {(!donateButton && !charityId)
            ? <div className="flex gap-x-5 justify-center">
              <span className='text-2xl'>Pick a charity</span>
              <img src={rightArrow} alt="right arrow" className="w-6" />
            </div>
            : <span className="items-center text-xl">
              Donate
            </span>}
        </Button>
      </div>
    </div>

    {!charityId && showFundraiserButton && (
      <Button
        className="w-full mt-5 rounded-lg shadow-md"
        variant='gm-share'
        data-test-id={GivematchTestId.startFundraiserButton}
        onClick={() => startFundraiser()}>
        <div className='flex items-center justify-center'>
          <img className="w-6 mr-5 " src={logoIcon} alt="Givematch" />
          <span className='font-semibold'>
            Start your fundraiser
          </span>
        </div>
      </Button>
    )}
  </div>
}

export default DonationCard
