import { useEffect, useState } from 'react'
import { useDebounce } from '@lib/hooks'
import { Input, SearchIcon, Spinner, Variant } from '@lib/components'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'
import { type CharityList, getCharities } from '~/state/charity'
import { type FundraiserStepComponent } from './types'
import { SelectableCharityCard } from './SelectableCharityCard'

type FRProperties = Pick<FundraiserPlan, 'country' | 'charity'>
type NextArguments = Pick<FundraiserPlan, 'charity'>

// TODO - User experience, the currently selected charity should be displayed separate from the list.
// This would go in line with a draft feature where the user can see previously selected charity
export const EnterFRCharity: FundraiserStepComponent<FRProperties, NextArguments> = ({
  processing,
  partial,
  next
}) => {
  // TODO add state for selected charity when fully implementing draft functionality
  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [charities, setCharities] = useState<CharityList[]>([])
  const [selectedCharity, setSelectedCharity] = useState(partial.charity ? partial.charity.charityID : '')
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText)

  useEffect(() => {
    let searchTerm = ''
    let onlyWithWeight = true
    if (debouncedSearchText.trim().length > 2) {
      onlyWithWeight = false
      searchTerm = debouncedSearchText.trim()
    }
    setLoading(true)
    setIsError(false)
    getCharities(partial.country, onlyWithWeight, searchTerm).then((res) => {
      setCharities(res)
    }).catch(() => {
      setIsError(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [debouncedSearchText, partial.country])

  const selectCharity = (ch: CharityList): void => {
    if (ch.charityID !== selectedCharity) {
      setSelectedCharity(ch.charityID)
      return
    }
    next({ charity: ch })
  }

  return (
    <div className="w-full flex flex-col">
      <div>
        <span className="text-gm-green-500 font-semibold mt-5 text-xl">Search for a charity</span>
        <div className="relative mt-5">
          <SearchIcon className="text-gm-green-500"/>
          <Input
            variant={Variant.gm}
            value={searchText}
            onChange={(event) => setSearchText(event.currentTarget.value)}
            className="w-full max-w-full pl-12 rounded" autoFocus
            placeholder="Name, location, cause"
          />
        </div>
      </div>

      {isError && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch charity data </p>}

      {loading && <Spinner className="m-auto mt-10" />}

      {!loading && !isError && (
        <>
          {!debouncedSearchText && <span className="text-gm-green-500 font-bold mt-5 text-xl mb-2">Pick from suggested charities</span>}
          <ul>
            {charities?.map(chrty => (
              <li key={chrty.charityID}>
                <SelectableCharityCard
                  processing={processing}
                  selected={selectedCharity === chrty.charityID}
                  charity={chrty}
                  onCharitySelect={() => selectCharity(chrty)}
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}
