import '~/index'
import { type FC } from 'react'
import { FundraiserLayout } from '../../FundraiserLayout'
import { FundraiserCard } from '../FundraiserCard'
import angleRight from '../../../assets/angle-right.svg'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import type Fundraiser from '~/state/fundraiser'
import { getFundraiserByDonor } from '~/state/fundraiser'
import { Spinner } from '@lib/components'
import { getCharity } from '~/state/charity'
import { Navigation } from '~/service/navigation'

interface UrlLink {
  title: string
  url: string
}
interface props {
  link: UrlLink
  fundraiser: Fundraiser
}
const GenerateFundRaiserLinks: FC<props> = ({ link }) => {
  return (
        <Link key={link.title} className="flex justify-between border border-gm-green-500 py-3 border-r-0 border-l-0 border-t-0" to={link.url}>
            <div>
                <span> {link.title}</span>
            </div>
            <div>
                <img src={angleRight} alt="forward" className="w-5 h-5" />
            </div>
        </Link>
  )
}
export const FundraiserCardDetails: FC = () => {
  const { fundraiserID } = useParams() as { fundraiserID: string }

  const { isLoading: fundraiserLoading, isError: fundraiserError, data: fundraiser } = useQuery(['fundraiser', fundraiserID], async () => getFundraiserByDonor(fundraiserID))
  const { isLoading: charityLoading, isError: charityError, data: charity } = useQuery(['charity', fundraiser?.charity_id], async () => getCharity(fundraiser!.charity_id), {
    enabled: !!fundraiser
  })
  const links: UrlLink[] = [
    {
      title: 'Donations',
      url: Navigation.fundraiserDonations(fundraiser?.fundraiser_id)
    },
    //   {
    //     title: 'Updates',
    //     url: '#'
    //   },
    //   {
    //     title: 'Invite Supporters',
    //     url: '#'
    //   },
    {
      title: 'Edit and Settings',
      url: Navigation.fundraiserSettings(fundraiser?.fundraiser_id)
    },
    {
      title: 'Team',
      url: Navigation.fundraiserTeam(fundraiser?.fundraiser_id)
    }
  ]
  return <FundraiserLayout title="">
        {(fundraiserLoading || charityLoading) && <Spinner className="m-auto" />}
        {(fundraiserError || charityError) && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch fundraiser or charity data </p>}
        {!fundraiserLoading && !fundraiserError && !charityLoading && !charityError && fundraiser && charity &&
            (
                <div>
                    {/* Fund Card Details  */}
                    <FundraiserCard isDetails={true} fundraiser={fundraiser} charity={charity} />

                    {/* Facebook Ads  */}
                    {/* <SocialMediaAds /> */}

                    {/* Links to do more  */}
                    <section>
                        {
                            links.map(link => {
                              return <GenerateFundRaiserLinks key={link.title} link={link} fundraiser={fundraiser} />
                            })
                        }
                    </section>
                </div>
            )
        }
    </FundraiserLayout>
}
