import { type FundraiserPlan, type TeamMember as NewTeamMember } from '~/hooks/useFundraiserPlan'
import { HttpRequestMethod } from 'http-core-constants'
import { callService, type Currency, type Country } from '@lib/services'
import { withAuth } from '@lib/hooks'
import { type EventDonation } from '~/state/charity'
import type { deleteFundraiserData, editFundRaiserData, editDraftFundRaiserData, fundraiserSettingsData } from '~/data/donationFormData'
import { type TeamMemberForm } from '~/components/fundraiser/types'

export interface Organiser {
  donor_id: string
  first_name: string
  last_name: string
  email: string
}

export interface EventBase {
  target_amount: number
  target_currency: Currency
  title: string
  story: string
  created_time: string
  active: boolean
  approved: boolean
  organiser: Organiser
  donations: EventDonation[]
  raised_amount: number
  donation_count: number
  referral_donation_amount: number
  non_referral_donation_amount: number
  matched_amount: number
  published_time?: string
  image_path: string
  youtube_url: string
  deleted: boolean
  newCoverPhoto?: string
}

interface Fundraiser extends EventBase {
  fundraiser_id: string
  charity_id: string
  charity_name: string
  uploadUrl: string
  donor_id: string
  is_campaign?: boolean
}

interface FundraiserSubImage {
  uploadUrl: string
  path: string
}
export default Fundraiser

export interface FundraiserInviteResponse {
  fundraiser_id: string
  invite_code: string
  accepted: boolean
  title: string
  image_path: string
  charity_name: string
  youtube_url: string
}

export interface TeamMember {
  firstName: string
  lastName: string
  email: string
  accepted: boolean
  role: string
  user_id: string
}
export interface FundraiserTeamResponse {
  team_members: TeamMember[]

}

interface FundraiserInviteUpdateStatus {
  status: string
}

export interface FundraiserDraft extends Fundraiser {
  country: Country
  postcode: string
  charity_opt_in: boolean
  team_members: NewTeamMember[]
  draft_updated_time: string
}

export interface FundraiserDraftCreateOrUpdateResponse {
  draft_updated_time: string
  fundraiser_id: string
  uploadUrl: string
}

export interface FundraiserDraftPublishResponse {
  fundraiser_id: string
  is_campaign: boolean
}

export const createFundraiserDraft = withAuth(async (fundraiserPlan: FundraiserPlan, reCaptchaToken: string): Promise<FundraiserDraftCreateOrUpdateResponse> => {
  return await callService('/api/fundraiser/draft/create', HttpRequestMethod.POST, {
    country: fundraiserPlan.country,
    postcode: fundraiserPlan.postcode,
    charity_id: fundraiserPlan.charity.charityID,
    target_currency: fundraiserPlan.charity.currency,
    target_amount: fundraiserPlan.target_amount,
    title: fundraiserPlan.title,
    story: fundraiserPlan.story,
    youtubeUrl: fundraiserPlan.youtubeUrl,
    charity_opt_in: fundraiserPlan.charity_opt_in,
    new_image_upload: fundraiserPlan.new_image_upload,
    team_members: fundraiserPlan.teamMembers,
    token: reCaptchaToken
  })
})
export const generateFundraiserImageUrl = withAuth(async (): Promise<FundraiserSubImage> => {
  return await callService(`/api/fundraiser/image/generate`, HttpRequestMethod.GET)
})
export const getFundraiserDraft = withAuth(async (): Promise<FundraiserDraft[]> => {
  return await callService(`/api/fundraiser/draft`, HttpRequestMethod.GET)
})

export const publishFundraiserDraft = withAuth(async (teamMembers: NewTeamMember[], reCaptchaToken: string): Promise<FundraiserDraftPublishResponse> => {
  return await callService(`/api/fundraiser/draft/publish`, HttpRequestMethod.POST, {
    team_members: teamMembers,
    token: reCaptchaToken
  })
})

export const updateDraftFundraiser = withAuth(async (editDraftFundRaiser: editDraftFundRaiserData, reCaptchaToken: string): Promise<FundraiserDraftCreateOrUpdateResponse> => {
  return await callService(`/api/fundraiser/draft/update`, HttpRequestMethod.PUT, {
    ...editDraftFundRaiser,
    token: reCaptchaToken
  })
})

export const updateFundraiser = withAuth(async (fundraiserID: string, editFundRaiser: editFundRaiserData): Promise<Fundraiser> => {
  return await callService(`/api/fundraiser/${fundraiserID}/update`, HttpRequestMethod.PUT, editFundRaiser)
})

export const updateFundraiserSetting = withAuth(async (fundraiserID: string, editFundRaiser: fundraiserSettingsData): Promise<Fundraiser> => {
  return await callService(`/api/fundraiser/${fundraiserID}/update/settings`, HttpRequestMethod.PUT, editFundRaiser)
})

export const deleteFundraiser = withAuth(async (fundraiserID: string, deleteFundraiser: deleteFundraiserData): Promise<Fundraiser> => {
  return await callService(`/api/fundraiser/${fundraiserID}`, HttpRequestMethod.DELETE, deleteFundraiser)
})

export const getFundraiserByDonor = withAuth(async (fundraiserID: string): Promise<Fundraiser> => {
  return await callService(`/api/donor/fundraiser/${fundraiserID}`, HttpRequestMethod.GET)
})

export const getFundraiserTeam = withAuth(async (fundraiserID: string): Promise<FundraiserTeamResponse> => {
  return await callService(`/api/fundraiser/${fundraiserID}/team`, HttpRequestMethod.GET)
})

export const inviteTeamMember = withAuth(async (fundraiserID: string, teamMember: TeamMemberForm): Promise<void> => {
  return await callService(`/api/fundraiser/${fundraiserID}/team`, HttpRequestMethod.POST, teamMember)
})

export const deleteTeamMember = withAuth(async (fundraiserID: string, userID: string): Promise<void> => {
  return await callService(`/api/fundraiser/${fundraiserID}/team/${userID}`, HttpRequestMethod.DELETE)
})

export const getFundraiser = async (fundraiserID: string, toCurrency: string = ''): Promise<Fundraiser> => {
  return await callService(`/api/fundraiser/${fundraiserID}/details?to_currency=${toCurrency}`, HttpRequestMethod.GET)
}

export const getAllFundraiser = withAuth(async (): Promise<Fundraiser[]> => {
  return await callService(`/api/fundraiser/all/donor`, HttpRequestMethod.GET)
})

export const getAllDonationsByFundraiserId = withAuth(async (fundraiserID: string): Promise<EventBase> => {
  return await callService(`/api/fundraiser/${fundraiserID}/donations/all`, HttpRequestMethod.GET)
})

export const toggleDonationAnonymity = withAuth(async (fundraiserID: string, shareCode: string, remainAnonymous: boolean): Promise<Fundraiser> => {
  return await callService(`/api/fundraiser/${fundraiserID}/donations/${shareCode}/toggle-anonymous`, HttpRequestMethod.POST, { remain_anonymous: remainAnonymous })
})

export const getFundraiserInvite = async (fundraiserID: string, inviteCode: string): Promise<FundraiserInviteResponse> => {
  return await callService(`/api/fundraiser/${fundraiserID}/invite/${inviteCode}`, HttpRequestMethod.GET)
}

export const updateFundraiserInvite = async (fundraiserID: string, inviteCode: string): Promise<FundraiserInviteUpdateStatus> => {
  return await callService(`/api/fundraiser/${fundraiserID}/invite/${inviteCode}`, HttpRequestMethod.POST)
}
