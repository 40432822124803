import { type FC } from 'react'
import { useAuth } from '@lib/hooks'
import { LoginAndCreateAccount } from './authentication/LoginAndCreateAccount'

const AuthenticatedRoute: FC<{ loginPage?: boolean }> = ({ children, loginPage }) => {
  const { user } = useAuth()
  return <>
    {user !== null && children}
    {user === null && <LoginAndCreateAccount loginPage={loginPage} />}
  </>
}

export default AuthenticatedRoute
