import { type FC } from 'react'
import { YoutubeEmbed } from '~/components/YoutubeEmbed'
import { Button } from '@lib/components'
import { GivematchFundraiserTestIds } from '@lib/testing'

interface props {
  coverPhoto: string
  youtubeEmbedUrl: string
  onEdit: () => void
  onDelete: () => void
  onContinueButton: () => void
}
export const EditedImageVideo: FC<props> = ({ coverPhoto, youtubeEmbedUrl, onEdit, onDelete, onContinueButton }) => {
  return <>
    {coverPhoto && (
      <img
        className="mt-5 max-w-lg"
        src={coverPhoto}
        onError={() => onDelete()}
        alt="selected pic"
      />
    )}
    {youtubeEmbedUrl && <YoutubeEmbed classname="mt-5 w-full aspect-[4/3]" embedUrl={youtubeEmbedUrl} />}
    <div className="flex justify-between">
      <a href="" className="underline" onClick={() => onEdit()}>Edit</a>
      <a href="" className="underline" onClick={() => onDelete()} data-test-id={GivematchFundraiserTestIds.deleteCoverPhotoButton}>Delete</a>
    </div>

    <Button
      data-test-id={GivematchFundraiserTestIds.continueButton}
      className="w-full mt-5 shadow-2xl"
      variant="gm-primary"
      disabled={coverPhoto === '' && youtubeEmbedUrl === ''}
      onClick={() => onContinueButton()}>
      <span>Continue</span>
    </Button>
  </>
}
