import { type FC } from 'react'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'

export const CREATE_FUNDRAISER_RESTART_QUERY = 'restart'

export enum FRCreateStep {
  Location = 'Location',
  Charity = 'Charity',
  TargetAmount = 'TargetAmount',
  CoverPhoto = 'CoverPhoto',
  Title = 'Title',
  TeamManage = 'TeamManage',
  Confirm = 'Confirm',
  NextSteps = 'NextSteps'
}

export const frStepPageTitles: Record<FRCreateStep, string> = {
  [FRCreateStep.Location]: 'Let\'s start fundraising!',
  [FRCreateStep.Charity]: 'Who you\'re raising funds for',
  [FRCreateStep.TargetAmount]: 'Your starting fundraising goal',
  [FRCreateStep.CoverPhoto]: 'Add a cover photo or video',
  [FRCreateStep.Title]: 'Tell donors why you\'re fundraising',
  [FRCreateStep.TeamManage]: 'Who will be managing this fundraiser?',
  [FRCreateStep.Confirm]: 'Confirm your charity',
  [FRCreateStep.NextSteps]: 'Your fundraiser is ready'
}

const STEPS_ORDER: readonly FRCreateStep[] = [
  FRCreateStep.Location,
  FRCreateStep.Charity,
  FRCreateStep.TargetAmount,
  FRCreateStep.CoverPhoto,
  FRCreateStep.Title,
  FRCreateStep.TeamManage,
  FRCreateStep.Confirm,
  FRCreateStep.NextSteps
] as const

export const AUTH_REQUIRED_STEPS: readonly FRCreateStep[] = [
  FRCreateStep.CoverPhoto,
  FRCreateStep.Title,
  FRCreateStep.TeamManage,
  FRCreateStep.Confirm,
  FRCreateStep.NextSteps
] as const

export const getInitialStepFromDraft = (fundraiser: FundraiserPlan): FRCreateStep => {
  const initialStep = STEPS_ORDER.find((step) => {
    switch (step) {
      case FRCreateStep.Location:
        return !fundraiser.country || !fundraiser.postcode
      case FRCreateStep.Charity:
        return !fundraiser.charity?.charityID
      case FRCreateStep.TargetAmount:
        return !fundraiser.target_amount
      case FRCreateStep.CoverPhoto:
        return !fundraiser.coverPhoto
      case FRCreateStep.Title:
        return !fundraiser.title || !fundraiser.story
      case FRCreateStep.TeamManage:
        return !fundraiser.teamMembers || fundraiser.teamMembers.length < 0
      case FRCreateStep.Confirm:
        return !fundraiser.charity_opt_in
      case FRCreateStep.NextSteps:
        return !!fundraiser.published_time
      default:
        return true
    }
  })

  return initialStep ?? FRCreateStep.Location
}

export const getNextStep = (currentStep: FRCreateStep): FRCreateStep | null => {
  const currentIndex = STEPS_ORDER.indexOf(currentStep)
  if (currentIndex < 0 || currentIndex === STEPS_ORDER.length - 1) return null
  return STEPS_ORDER[currentIndex + 1]
}

export const getPreviousStep = (currentStep: FRCreateStep): FRCreateStep | null => {
  const currentIndex = STEPS_ORDER.indexOf(currentStep)
  if (currentIndex <= 0) return null
  return STEPS_ORDER[currentIndex - 1]
}

type CreateFRStepProps<P extends object, N = undefined, Props extends object = object> = Props & {
  processing: boolean
  partial: P
  next: (
    args: N extends undefined ? Required<P> : Required<N>
  ) => void
}
export type FundraiserStepComponent<
  P extends object,
  N = undefined,
  Props extends object = object
> = FC<CreateFRStepProps<P, N, Props>>
