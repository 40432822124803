import { type Currency, CustomerIO, getCurrencySymbol, MixpanelInstance, ObjectUtil, penniesToPound, TrackingService } from '@lib/services'
import { type CharityList, type Charity } from '../state/charity'
import { extractValueFromURL, isEvent, LandingPageType } from '@givematch/common'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'
import { type User } from '@lib/hooks'
import { SessionStorage } from './sessionStorage'
import type Fundraiser from '~/state/fundraiser'

export const MIXPANEL_PROD_TOKEN = '5d0d57b1fbd6a9ba44347ea371ee0a9c'
export const MIXPANEL_TEST_TOKEN = 'c9382ccdbb0fe59777f9556b48554001'

export class CharityTracker {
  referrer_charity_name?: string
  referrer_charity_id?: string
  partner_charity_name?: string
  partner_charity_id?: string
}
export class SharedCharity {
  charity?: Charity
  isPartnerCharityDonation: boolean
}

export class PartnerCharity {
  name: string
  id: string
}

export class FundraiserTracker {
  fundraiser_organiser_name?: string
  fundraiser_id?: string
  fundraiser_name?: string
}
export class GMTracking extends TrackingService {
  public initialize (): void {
    super.initialize('gm')
  }

  goToCharityList (): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'awareness',
      eventAction: 'go to charity list',
      eventLabel: 'click'
    })
    this.trackEvent(undefined, 'GoToCharityList', {})
  }

  identify (email: string): void {
    MixpanelInstance.tryIdentify(email)
    CustomerIO.identify(email)
  }

  genericEvent (eventTitle: string, charity: Charity | CharityList, donationAmount: number, extra?: Record<string, string>): void {
    const props: Record<string, string> = this.getCharityExtraData()
    const campaignDetails = this.getCampaignDetails()
    window?.ga?.('set', 'dimension2', charity.name)
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: eventTitle,
      eventLabel: 'click'
    })
    this.trackEvent(extra?.email, eventTitle, {
      charity_name: charity.name,
      charity_id: (charity as Charity).charity ?? (charity as CharityList).charityID,
      donation_amount: donationAmount,
      currency_ticker: charity.currency,
      currency_symbol: getCurrencySymbol(charity.currency),
      ...props,
      ...extra,
      ...campaignDetails
    })
  }

  selectCharity (charity: Charity, donationAmount: number): void {
    this.genericEvent('SelectCharity', charity, donationAmount)
  }

  selectAPICharityNotApproved (charity: Charity | CharityList, donationAmount: number, pathname: string, email?: string): void {
    const extra: Record<string, string> = { page_url: pathname }
    if (email) {
      extra.email = email
    }
    this.genericEvent('SelectAPICharityNotApproved', charity, donationAmount, extra)
  }

  understoodHowItWorks (charity: Charity, donationAmount: number): void {
    this.genericEvent('UnderstoodHowItWorks', charity, donationAmount, this.getFundraiserExtraData())
  }

  changeCountry (country: string): void {
    this.trackEvent(undefined, 'ChangeCountry', {
      country_name: country
    })
  }

  addCause (charity: Charity, causeName: string, donationAmount: number): void {
    this.trackEvent(undefined, 'AddCause', {
      charity_name: charity.name,
      charity_id: charity.charity,
      cause_name: causeName,
      donation_amount: donationAmount,
      currency_ticker: charity.currency,
      currency_symbol: getCurrencySymbol(charity.currency)
    })
  }

  confirmAmount (charity: Charity, causes: Record<string, number>): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: 'confirm amount',
      eventLabel: 'submit'
    })
    this.trackEvent(undefined, 'ConfirmAmount', {
      charity_name: charity.name,
      charity_id: charity.charity,
      currency_ticker: charity.currency,
      currency_symbol: getCurrencySymbol(charity.currency),
      ...this.getCausesProps(causes, charity),
      ...this.getCampaignDetails(),
      ...this.getCharityExtraData()
    })
  }

  getCharityExtraData (): Record<string, any> {
    const extra: CharityTracker = {}
    const partnerCharity = sessionStorage.getItem(SessionStorage.partnerCharityKey)
    if (partnerCharity) {
      const props = JSON.parse(partnerCharity)
      if (props.id) {
        extra.partner_charity_id = props.id
      }
      if (props.name) {
        extra.partner_charity_name = props.name
      }
    }

    const referrerCharity = sessionStorage.getItem(SessionStorage.referrerCharityKey)
    if (referrerCharity) {
      const props = JSON.parse(referrerCharity)
      if (props.id) {
        extra.referrer_charity_id = props.id
      }
      if (props.name) {
        extra.referrer_charity_name = props.name
      }
    }
    return extra
  }

  getCampaignDetails (): Record<string, string> {
    const campaign: { campaign_name?: string } = {}
    const campaignName = sessionStorage.getItem(SessionStorage.campaignName)
    if (campaignName) {
      campaign.campaign_name = campaignName
    }
    return campaign
  }

  getFundraiserExtraData (): Record<string, any> {
    const extra: FundraiserTracker = {}
    const currentFundraiser = sessionStorage.getItem('current_fundraiser')
    if (currentFundraiser) {
      const props = JSON.parse(currentFundraiser)
      if (props.fundraiser_organiser_name !== '') {
        extra.fundraiser_organiser_name = props.fundraiser_organiser_name
      }
      extra.fundraiser_id = props.fundraiser_id
      extra.fundraiser_name = props.fundraiser_name
    }
    return extra
  }

  getPageViewTrackingData (): Record<string, any> {
    const extra: Record<string, any> = { ...this.getCharityExtraData(), ...this.getFundraiserExtraData(), ...this.getCampaignDetails() }
    return extra
  }

  donateFundraiserButton (donationAmount: number, currency: Currency, isAmountPage?: boolean): void {
    const gaName = isAmountPage ? 'donate fundraiser amount button' : 'donate fundraiser button'
    const mpName = isAmountPage ? 'DonateFundraiserAmountButton' : 'DonateFundraiserButton'
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: gaName,
      eventLabel: 'submit'
    })
    this.trackEvent(undefined, mpName, {
      donation_amount: donationAmount,
      currency_ticker: currency,
      currency_symbol: getCurrencySymbol(currency),
      ...this.getCharityExtraData(),
      ...this.getFundraiserExtraData(),
      ...this.getCampaignDetails()
    })
  }

  proceedToChooseCharity (donationAmount: number, currency: Currency): void {
    const props: Record<string, string> = this.getCharityExtraData()
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: 'goto charity selection',
      eventLabel: 'submit'
    })
    this.trackEvent(undefined, 'GoToCharitySelection', {
      donation_amount: donationAmount,
      currency_ticker: currency,
      currency_symbol: getCurrencySymbol(currency),
      ...props,
      ...this.getCampaignDetails()
    })
  }

  paymentSuccessful (shareCode: string, paymentDetails: PaymentDetails, charity: Charity, userDetails: UserDetails): void {
    const props: Record<string, string> = this.getCharityExtraData()
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: 'payment successful',
      eventLabel: 'submit'
    })
    // save this charity name as it is needed for tracking later
    sessionStorage.setItem('paid_charity', paymentDetails.charityName)

    window?.ga?.('ecommerce:addTransaction', {
      id: shareCode,
      revenue: paymentDetails.totalAmount
    })

    window?.ga?.('ecommerce:send')
    window?.ga?.('ecommerce:clear')

    window?.fbq?.('track', 'Purchase', {
      value: penniesToPound(paymentDetails.totalAmount),
      currency: paymentDetails.currency.toUpperCase()
    })

    window?.gtag?.('event', 'Donate', {
      value: penniesToPound(paymentDetails.totalAmount),
      currency: paymentDetails.currency.toUpperCase(),
      transaction_id: shareCode,
      send_to: 'AW-11118568126/zBhKCNbjyZIYEL7F37Up'
    })

    window?.dataLayer?.push({ ecommerce: null })
    window?.dataLayer?.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: shareCode,
        value: penniesToPound(paymentDetails.totalAmount),
        currency: paymentDetails.currency.toUpperCase(),
        items: Object.keys(paymentDetails.causes).map(causeId => {
          return {
            item_id: charity.charity,
            item_name: charity.name,
            item_list_id: causeId,
            item_list_name: charity.causes.find(cause => cause.causeID === causeId)!.name,
            price: penniesToPound(paymentDetails.causes[causeId])
          }
        })
      }
    })

    CustomerIO.identify(userDetails.email, {
      first_name: userDetails.firstName,
      last_name: userDetails.lastName,
      country: userDetails.country,
      address: userDetails.address
    })
    MixpanelInstance.tryIdentify(userDetails.email)
    MixpanelInstance.setPeople({
      $email: userDetails.email,
      $name: `${userDetails.firstName} ${userDetails.lastName}`,
      email: userDetails.email,
      country: userDetails.country,
      address: userDetails.address,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName
    })
    const hearFromCharity = `hear_from_${charity.charity}`
    const extraPaymentData: Record<string, any> = {}
    if (isEvent(paymentDetails.causeType)) { // only add event data if it is not empty and its an event
      extraPaymentData.eventId = paymentDetails.eventId
      extraPaymentData.eventName = charity.causes.find(c => c.causeID === paymentDetails.eventId)!.name
    }

    this.trackEvent(userDetails.email, 'PaymentSuccessfulV2', {
      total_amount: paymentDetails.totalAmount + paymentDetails.transactionFee + paymentDetails.tipAmount,
      tip_amount: paymentDetails.tipAmount,
      tip_percentage: paymentDetails.tipPercentage,
      gift_aid: paymentDetails.giftAid,
      transaction_fee: paymentDetails.transactionFee,
      hear_from_us: paymentDetails.hearFromUs,
      [hearFromCharity]: paymentDetails.hearFromCharity,
      country: userDetails.country,
      address: userDetails.address,
      first_name: userDetails.firstName,
      causeType: paymentDetails.causeType,
      ...extraPaymentData,
      donation_amount: paymentDetails.totalAmount,
      charity_name: charity.name,
      charity_id: charity.charity,
      currency_ticker: paymentDetails.currency,
      currency_symbol: getCurrencySymbol(paymentDetails.currency),
      pay_with: paymentDetails.payWith,
      referral_link: `${window.location.origin}/link/${shareCode}`,
      ...props,
      ...this.getFundraiserExtraData(),
      ...this.getCampaignDetails(),
      ...this.getCausesProps(paymentDetails.causes, charity),
      hear_from_us_about_ramadan: paymentDetails.hearFromUsAboutRamadan
    }
    )
  }

  shareInviteLinkType (type: string, referralLink: string, isWidget: boolean, charityId: string): void {
    window?.ga?.('set', 'dimension3', type)
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'activation',
      eventAction: 'social share buttons',
      eventLabel: 'click'
    })

    const shareCode = extractValueFromURL(referralLink, 'link')
    const fundraiserId = extractValueFromURL(referralLink, 'fr')
    let eventName: string
    if (fundraiserId) {
      eventName = 'FundraiserShareInviteLinkType'
    } else {
      eventName = isWidget ? 'WidgetShareInviteLinkType' : 'ShareInviteLinkType'
    }
    const extra: Record<string, string> = this.getCharityExtraData()
    const props: Record<string, any> = { link: type, referral_link: referralLink, ...extra, page_url: window.location.toString(), ...this.getFundraiserExtraData(), ...this.getCampaignDetails() }
    if (shareCode !== '') {
      props.share_code = shareCode
    }
    if (isWidget) { props.partner_charity_name = charityId }
    this.trackEvent(undefined, eventName, props)
  }

  shareInviteLink (): void {
    const props: Record<string, string> = this.getCharityExtraData()
    const currentCharity = sessionStorage.getItem('paid_charity') ?? ''
    this.trackEvent(undefined, 'ShareInviteLink', { ...props, charity_name: currentCharity, ...this.getFundraiserExtraData(), ...this.getCampaignDetails() })
  }

  shareFundraiserButton (): void {
    const props: Record<string, string> =
      window?.ga?.('send', {
        hitType: 'event',
        eventCategory: 'activation',
        eventAction: 'go to fundraiser share  options',
        eventLabel: 'click'
      })
    this.trackEvent(undefined, 'ShareFundraiserButton', {
      ...props,
      ...this.getCharityExtraData(),
      ...this.getFundraiserExtraData(),
      ...this.getCampaignDetails()
    })
  }

  loginClick (): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'activation',
      eventAction: 'go to login page',
      eventLabel: 'click'
    })
    this.trackEvent(undefined, 'LoginClick', {
      ...this.getCampaignDetails()
    })
  }

  loginEmail (email: string): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'activation',
      eventAction: 'login with email',
      eventLabel: 'click'
    })
    this.trackEvent(email, 'LoginEmail', {
      ...this.getCampaignDetails(),
      email
    })
  }

  loginCode (email: string): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'activation',
      eventAction: 'login with code',
      eventLabel: 'click'
    })
    this.trackEvent(email, 'LoginCode', {
      ...this.getCampaignDetails(),
      email
    })
  }

  logOut (email: string | undefined): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'activation',
      eventAction: 'logout',
      eventLabel: 'click'
    })
    this.trackEvent(email, 'LogOut', {
      email,
      ...this.getCampaignDetails()
    })
  }

  shareInviteLinkButton (): void {
    const props: Record<string, string> = this.getCharityExtraData()
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'activation',
      eventAction: 'go to share link options',
      eventLabel: 'click'
    })
    const currentCharity = sessionStorage.getItem('paid_charity') ?? ''
    this.trackEvent(undefined, 'ShareInviteLinkButton', { ...props, charity_name: currentCharity, ...this.getFundraiserExtraData(), ...this.getCampaignDetails() })
  }

  shareLinkOptionsIframe (): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'activation',
      eventAction: 'iframe loaded',
      eventLabel: 'view'
    })
    this.trackEvent(undefined, 'ShareLinkOptionsIFrame', {})
  }

  back (charity: Charity): void {
    this.trackEvent(undefined, 'Back', {
      charity_name: charity.name,
      charity_id: charity.charity
    })
  }

  startYourFundraiser (): void {
    const props: Record<string, string> = this.getCharityExtraData()

    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: 'start your fundraiser',
      eventLabel: 'click'
    })
    this.trackEvent(undefined, 'StartYourFundraiser', {
      page_url: window.location.toString(),
      ...props
    })
  }

  myFundraiser (email: string | undefined): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: 'My fundraiser',
      eventLabel: 'click'
    })
    this.trackEvent(email, 'MyFundraisers', {
      ...this.getCampaignDetails(),
      email
    })
  }

  charityDashboard (email: string | undefined): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: 'Charity dashboard',
      eventLabel: 'click'
    })
    this.trackEvent(email, 'CharityDashboard', {
      ...this.getCampaignDetails(),
      email
    })
  }

  myDonations (email: string | undefined): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: 'My donations',
      eventLabel: 'click'
    })
    this.trackEvent(email, 'MyDonations', {
      ...this.getCampaignDetails()
    })
  }

  manageDonation (email: string | undefined): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: 'Manage donation',
      eventLabel: 'click'
    })
    this.trackEvent(email, 'ManageDonation', {
      ...this.getCampaignDetails()
    })
  }

  trackEditFundraiser (fundraiser: Fundraiser): void {
    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: 'Edit your fundraiser',
      eventLabel: 'click'
    })
    this.trackEvent(fundraiser.organiser.email, 'EditYourFundraiser', {
      ...this.getCampaignDetails(),
      currency_ticker: fundraiser.target_currency,
      currency_symbol: getCurrencySymbol(fundraiser.target_currency),
      title_content: fundraiser.title,
      story_content: fundraiser.story,
      fundraising_goal: fundraiser.target_amount,
      fundraiser_organiser_name: fundraiser.organiser.first_name + ' ' + fundraiser.organiser.last_name,
      fundraiser_id: fundraiser.fundraiser_id,
      fundraiser_name: fundraiser.title,
      email: fundraiser.organiser.email
    })
  }

  locationSelection (country: string, postCode: string): void {
    const props: Record<string, string> = this.getCharityExtraData()

    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: 'submit location',
      eventLabel: 'submit'
    })
    this.trackEvent(undefined, 'LocationSelection', {
      page_url: window.location.toString(),
      location: country,
      post_code: postCode,
      ...props
    })
  }

  trackGenericFundraiserEvent (fundraiser: FundraiserPlan, event: string, extra?: Record<string, any>): void {
    const props: Record<string, string> = this.getCharityExtraData()

    window?.ga?.('send', {
      hitType: 'event',
      eventCategory: 'acquisition',
      eventAction: event,
      eventLabel: 'submit'
    })
    this.trackEvent(undefined, event, {
      page_url: window.location.toString(),
      location: fundraiser.country,
      post_code: fundraiser.postcode,
      charity_name: fundraiser.charity.name,
      currency_ticker: fundraiser.charity.currency,
      currency_symbol: getCurrencySymbol(fundraiser.charity.currency),
      fundraising_goal: fundraiser.target_amount,
      is_campaign: fundraiser.is_campaign,
      ...extra,
      ...props
    })
  }

  pickCharity (fundraiser: FundraiserPlan, charityName: string): void {
    this.trackGenericFundraiserEvent(fundraiser, 'PickCharity', { charity_name: charityName, fundraising_goal: 0 })
  }

  fundraisingGoal (fundraiser: FundraiserPlan, amount: number): void {
    this.trackGenericFundraiserEvent(fundraiser, 'FundraisingGoal', {
      currency_ticker: fundraiser.charity.currency,
      currency_symbol: getCurrencySymbol(fundraiser.charity.currency),
      fundraising_goal: amount
    })
  }

  createFundraiserAccount (fundraiser: FundraiserPlan, tipsSignup: boolean): void {
    this.trackGenericFundraiserEvent(fundraiser, 'CreateFundraiserAccount', { tips_signup: tipsSignup })
  }

  verifyFundraiserAccount (fundraiser: FundraiserPlan): void {
    this.trackGenericFundraiserEvent(fundraiser, 'VerifyFundraiserAccount')
  }

  addCoverImage (fundraiser: FundraiserPlan): void {
    this.trackGenericFundraiserEvent(fundraiser, 'AddCoverImage')
  }

  addFundraiserDetails (fundraiser: FundraiserPlan, title: string, story: string): void {
    this.trackGenericFundraiserEvent(fundraiser, 'FundraiserDetails', {
      title_content: title,
      story_content: story
    })
  }

  fundraiserPreview (fundraiser: FundraiserPlan, title: string, story: string): void {
    this.trackGenericFundraiserEvent(fundraiser, 'FundraiserPreview', {
      title_content: title,
      story_content: story
    })
  }

  completeFundraiser (fundraiser: FundraiserPlan, hearFromCharity: boolean, user: User | null): void {
    const extra: Record<string, any> = {}
    extra[`hear_from_${fundraiser.charity.name.replaceAll(' ', '').toLowerCase()}`] = hearFromCharity
    extra.fundraiser_name = fundraiser.title
    extra.fundraiser_organiser_name = user ? `${user.firstName} ${user.lastName}` : ''
    this.trackGenericFundraiserEvent(fundraiser, 'CompleteFundraiser', extra)
  }

  letsGoFundraiser (): void {
    const props: Record<string, string> = this.getCharityExtraData()
    this.trackEvent(undefined, 'LetsGoFundraiser', props)
  }

  pageViewedWithTracking (pathname: string, email: string | undefined): void {
    const props = this.getPageViewTrackingData()
    this.pageView(pathname, email, props)
  }

  getRamadanCampaignName (): string {
    return `${LandingPageType.Ramadan.toLowerCase()}-${new Date().getFullYear()}`
  }

  private getCausesProps (causes: Record<string, number>, charity: Charity): Record<string, any> {
    const causeProps: Record<string, any> = {}
    if (!ObjectUtil.isEmptyObject(causes)) {
      const causesIds = Object.keys(causes)
      const causesValues = Object.values(causes)
      const causesNames = causesIds.map(id => charity.causes.find(c => c.causeID === id)!.name)
      const total = causesValues.reduce((sum, v) => sum + v, 0)
      const eventCauses = causesIds.map((id, i) => ({ name: causesNames[i], donation_amount: causes[id] }))
      causeProps.causes_amounts = causesValues
      causeProps.causes_names = causesNames
      causeProps.donation_amount = total
      causeProps.causes = eventCauses
      causeProps.number_of_causes = causesNames.length
    }
    return causeProps
  }

  isTrackableUrl (pathName: string | null | undefined): boolean {
    // We're testing both Vite (React) & Next.js path names; one of them is null or undefined
    // - Vite
    //    -> import { useLocation } from 'react-router-dom'
    //    -> const { pathname } = useLocation()
    // - Next.js
    //    -> import { usePathname } from 'next/navigation'
    //    -> const nextJsPathname: string | null = usePathname()
    if (typeof pathName !== 'string') {
      return false
    }

    const allowed = pathName.includes('/fr/share')
    const ignored = pathName.includes('/link/') || pathName.includes('/fr/') || pathName.endsWith('/ramadan')

    return allowed || !ignored // always return true is it is a fr/share link, else, return the opposite of the ignored url outcome
  }
}

export const Tracking = new GMTracking()

export interface PaymentDetails {
  totalAmount: number
  tipAmount: number
  tipPercentage: number
  giftAid: boolean
  currency: Currency
  transactionFee: number
  eventId: string
  charityName: string
  hearFromUs: boolean
  hearFromCharity: boolean
  causeType: string
  fundraiserId: string
  fundraiserName: string
  payWith: string
  causes: Record<string, number>
  hearFromUsAboutRamadan: boolean
}

export interface UserDetails {
  firstName: string
  lastName: string
  address: string
  country: string
  email: string
}
