import { RecaptchaV3 } from '@lib/services'
import { type editDraftFundRaiserData } from '~/data/donationFormData'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'
import { type FundraiserDraft } from '~/state/fundraiser'

export const recaptchaFundraiserDraftToken = async (
  action: 'create' | 'update' | 'publish'
): Promise<string> => {
  const recaptcha = new RecaptchaV3(`fundraiser_draft_${action}`)
  return await recaptcha.getToken()
}

export const frDraftToPlan = (draft: FundraiserDraft): FundraiserPlan => ({
  fundraiser_id: draft.fundraiser_id,
  country: draft.country,
  postcode: draft.postcode,
  charity: {
    charityID: draft.charity_id,
    name: draft.charity_name,
    currency: draft.target_currency,
    causes: {},
    country: '',
    weight: 0,
    charityApi: '',
    coverImage: '',
    logoImage: '',
    status: ''
  },
  target_amount: draft.target_amount,
  title: draft.title,
  story: draft.story,
  youtubeUrl: draft.youtube_url,
  charity_opt_in: draft.charity_opt_in,
  coverPhoto: draft.image_path ?? draft.newCoverPhoto,
  new_image_upload: false,
  teamMembers: draft.team_members,
  draft_updated_time: draft.draft_updated_time,
  published_time: draft.published_time
})

export const getDraftUpdatePayload = (
  fundraiserDraftId: string,
  plan: FundraiserPlan
): editDraftFundRaiserData => ({
  fundraiser_id: fundraiserDraftId,
  country: plan.country,
  postcode: plan.postcode,
  charity_id: plan.charity.charityID,
  charity_name: plan.charity.name,
  target_amount: plan.target_amount,
  target_currency: plan.charity.currency,
  title: plan.title,
  story: plan.story,
  youtube_url: plan.youtubeUrl,
  charity_opt_in: plan.charity_opt_in,
  new_image_upload: plan.new_image_upload,
  team_members: plan.teamMembers
})
