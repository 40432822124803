import { Button } from '@lib/components'
import { useNavigate } from 'react-router-dom'
import { type FC, useState } from 'react'
import type Fundraiser from '~/state/fundraiser'
import { deleteFundraiser, updateFundraiserSetting } from '~/state/fundraiser'
import trashLogo from '../../../assets/trash.svg'
import { useForm } from 'react-hook-form'
import { Navigation } from '~/service/navigation'
import { useMutation } from 'react-query'
import { type deleteFundraiserData, type fundraiserSettingsData } from '~/data/donationFormData'

interface props {
  fundraiser: Fundraiser
}

export const FundraiserSetting: FC<props> = ({ fundraiser }) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const form = useForm<fundraiserSettingsData>({
    defaultValues: {
      fundraiser_id: fundraiser.fundraiser_id,
      active: fundraiser.active
    }
  })
  const { handleSubmit, register, getValues } = form
  const onDeleteClick = (): void => {
    if (window.confirm('Are you sure?')) {
      completeFundraiserDelete.mutate()
    }
  }

  const completeFundraiserSettingUpdate = useMutation(async () => {
    // prepare record for update
    setProcessing(true)
    setError('')
    const editedFundRaiserData: fundraiserSettingsData = getValues()
    // send record to the api
    const fundraiserResponse = await updateFundraiserSetting(editedFundRaiserData.fundraiser_id, editedFundRaiserData)
    return fundraiserResponse
  }
  , {
    onSuccess: () => {
      console.log('success')
      // navigate to the details page of the fundraiser
      navigate(Navigation.fundraiserDetails(fundraiser.fundraiser_id))
    },
    onError: (e) => {
      console.error(e)
      setError('We\'re sorry there has been a problem updating your fundraiser')
      setProcessing(false)
    }
  }
  )
  const completeFundraiserDelete = useMutation(async () => {
    // prepare record for update
    setProcessing(true)
    setError('')
    const deletefundraiserData: deleteFundraiserData = {
      deleted: true
    }
    console.log(deletefundraiserData)
    const fundraiserResponse = await deleteFundraiser(fundraiser.fundraiser_id, deletefundraiserData)
    return fundraiserResponse
  }
  , {
    onSuccess: () => {
      console.log('success')
      // navigate to the user dashboard of fundraisers
      navigate(Navigation.fundraiserDashboard())
    },
    onError: (e) => {
      console.error(e)
      setError('We\'re sorry there has been a problem deleting your fundraiser')
      setProcessing(false)
    }
  }
  )

  return <section>
        {/* eslint-disable @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(async () => completeFundraiserSettingUpdate.mutate())} id="EditFundraiserSettingForm" className="flex flex-col gap-y-8 my-4">
            {/* eslint-enable @typescript-eslint/no-misused-promises */}
            {/* Fundraiser page  */}
            <div className="flex flex-col gap-y-2">
                <h3 className="text-gm-green font-semibold"> Fundraiser Page </h3>
                <div className="flex flex-row gap-x-4">
                    <input type="checkbox" {...register('active')} className="border border-gm-green" />
                    <span> Allow my fundraiser to accept donations</span>
                </div>
                {/* <div className="flex flex-row gap-x-4">
                    <input type="checkbox" className="border border-gm-green" />
                    <span> Allow my fundraiser to leave words of support on my fundraiser </span>
                </div>
                <div className="flex flex-row gap-x-4">
                    <input type="checkbox" className="border border-gm-green" />
                    <span> Allow my fundraiser to appear in search results</span>
                </div> */}

            </div>
            {/* Notifications  */}
            {/* <div className="flex flex-col gap-y-2">
                <h3 className="text-gm-green font-semibold"> Notifications </h3>
                <div className="flex flex-row gap-x-4">
                    <input type="radio" className="border border-gm-green " />
                    <span> Receive an email for every donation I get</span>
                </div>
                <div className="flex flex-row gap-x-4">
                    <input type="radio" className="border border-gm-green" />
                    <span> Receive a daily summary email of the donations I get</span>
                </div>

            </div> */}
            {/* Delete Area  */}
            <div className="flex flex-col gap-y-2">
                <h3 className="text-gm-green font-semibold"> Delete Fundraiser </h3>
                <div className="flex text-justify">
                    Deleting will remove your manage access to this fundraiser. If you simply want to stop receiving donations, you can uncheck the box that allows donations instead.
                </div>

                <div>
                    <Button disabled={processing} variant="gm-secondary-md" className="text-gm-red py-2 px-3" onClick={(e) => { e.preventDefault(); onDeleteClick() }}>
                        <div className="flex flex-row justify-between items-center gap-x-1">
                            <img src={trashLogo} alt="edit" className="w-3 h-3 flex align-middle " />
                            <span> Delete Fundraiser </span>
                        </div>
                    </Button>
                </div>
            </div>
            {/* Action Buttons  */}
            <div className="flex flex-col gap-y-4">
                <Button variant="gm-primary-md" className="w-full" type="submit" disabled={processing} >
                    Save
                </Button>
            </div>
            <div>
                {error !== '' && <p
                    className="p-4 text-sm text-right text-gm-red">{error}</p>}
            </div>
        </form>
    </section>
}
