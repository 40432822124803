import { Button } from '@lib/components'
import { type FC } from 'react'
import CharityFundraiserCard from './CharityFundraiserCard'
import { Tracking } from '~/service/tracking'
import { Navigation } from '~/service/navigation'
import { useNavigate } from 'react-router-dom'
interface CharityDetails {
  charityID: string
  landingPageData: any
}
interface FundraiserProps {
  charityDetails: CharityDetails
}
const CharityCards: FC<FundraiserProps> = ({ charityDetails }) => {
  const navigate = useNavigate()
  const startFundraiser = (): void => {
    Tracking.startYourFundraiser()
    navigate(Navigation.fundraiserCreate(true))
  }
  return <section className="px-4 md:py-10 py-5 sm:px-20 md:px-10 lg:px-40 bg-gm-gray-50">
    <div className='text-center md:my-10'>
      <h1 className="md:text-5xl text-gm-black text-xl font-bold my-3">
        Where we need your help today
      </h1>
      <div className="md:max-w-4xl mx-auto md:my-10">
        <p className='md:text-3xl text-gm-black text-sm font-medium text-center'>
          Some initiatives are more pressing than others. To help you redirect your fund to a cause close to your heart, we have highlighted a few that need urgent support.
        </p>
      </div>
    </div>
    <br/>
    <CharityFundraiserCard charityDetails={charityDetails} />
    <div className='flex justify-center items-center md:mt-10 mt-7 mb-2 md:mb-0'>
      <Button className="rounded-lg max-w-xl flex items-center justify-center" variant='gm-charity-fundraiser-outline' onClick={() => startFundraiser()}>
          <span className='md:text-3xl text-xl font-bold'>
            Create Fundraiser
          </span>
      </Button>
    </div>
  </section>
}

export default CharityCards
