import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Currency } from '@lib/services'
import { useAuth } from '@lib/hooks'
import Footer from '~/components/Footer'
import logo from '../../assets/logo.svg'
import shareLogo from '../../assets/share.svg'
import { FundraiserDonation } from '~/components/fundraiser/display/FundraiserDonation'
import { type Charity } from '~/state/charity'
import { type FundraiserPlan, useEditFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { type Organiser } from '~/state/fundraiser'
import type Fundraiser from '~/state/fundraiser'

export const PreviewFundraiser: FC<{ fundraiserPlan?: FundraiserPlan, onDone?: () => void }> = ({
  fundraiserPlan,
  onDone
}) => {
  const navigate = useNavigate()
  const { editFundraiser } = useEditFundraiserPlan()
  const { user } = useAuth()
  const organiser: Organiser = {
    donor_id: user ? user.userId : '',
    first_name: user ? user.firstName : '',
    last_name: user ? user.lastName : '',
    email: user ? user.email : ''
  }
  let currentFundraiser: Fundraiser = {
    fundraiser_id: '',
    charity_id: '',
    charity_name: '',
    uploadUrl: '',
    target_amount: 0,
    target_currency: Currency.USD,
    title: '',
    story: '',
    created_time: '',
    active: false,
    approved: false,
    organiser,
    donations: [],
    raised_amount: 0,
    donation_count: 0,
    referral_donation_amount: 0,
    non_referral_donation_amount: 0,
    matched_amount: 0,
    image_path: '',
    youtube_url: '',
    deleted: false,
    donor_id: ''
  }
  if (fundraiserPlan) {
    let imgURL: URL | undefined
    if (!!fundraiserPlan.coverPhoto && fundraiserPlan.coverPhoto.startsWith('https')) {
      imgURL = new URL(fundraiserPlan.coverPhoto)
    }
    currentFundraiser = {
      ...currentFundraiser,
      ...fundraiserPlan,
      title: fundraiserPlan.title ?? '',
      story: fundraiserPlan.story ?? '',
      active: true,
      newCoverPhoto: imgURL ? imgURL.pathname : undefined,
      image_path: imgURL ? imgURL.pathname : '',
      youtube_url: fundraiserPlan.youtubeUrl,
      charity_name: fundraiserPlan.charity.name,
      charity_id: fundraiserPlan.charity.charityID
    }
  } else {
    currentFundraiser = { ...editFundraiser, active: true }
  }
  const charity: Charity = {
    charity: currentFundraiser.charity_id,
    charity_api: '',
    status: '',
    campaign: '',
    country: '',
    name: currentFundraiser.charity_name,
    about: '',
    causes: [],
    gift_aid: false,
    tax_receipt: false,
    currency: Currency.GBP,
    logo_image: ''
  }
  return (
    <section className="min-h-screen w-full flex flex-col md:items-center">
      <section className='flex flex-col my-5 px-4 max-w-lg md:max-w-none w-full border-b border-gm-green-200'>
        <div className="flex flex-row justify-between items-baseline md:px-12 lg:px-40">
          <span className='text-md font-bold py-2'>Preview Mode</span>
          <button
            onClick={() => onDone ? onDone() : navigate(-1)}
            className="underline text-gm-green text-sm cursor-pointer"
          >
            Done
          </button>
        </div>
      </section>
      <section className='flex flex-col px-4 pb-10 max-w-lg md:max-w-none w-full gap-y-4'>
        <div className="flex flex-row justify-between items-baseline md:px-12 lg:px-40">
          <img className="w-16" src={logo} alt="Givematch" />
          <img className="w-4 h-4 cursor-pointer" src={shareLogo} alt="share fundraiser" />
        </div>
        <FundraiserDonation
          isPreview={true}
          fundraiser={currentFundraiser}
          onDonateButtonClick={() => { }}
          eventID={currentFundraiser.fundraiser_id}
          charity={charity}
        />
      </section>
      <Footer />
    </section>
  )
}
