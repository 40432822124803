import { Button } from '@lib/components'
import { type FC } from 'react'
import { YoutubeEmbed } from '~/components/YoutubeEmbed'
import ProgressBar from '@ramonak/react-progress-bar'
import { displayTidyCurrency } from '@lib/services'
import { Navigation } from '~/service/navigation'
import { useNavigate } from 'react-router-dom'
import { FundraiserImage } from './fundraiser/FundraiserCard'

interface CharityDetails {
  charityID: string
  landingPageData: any
}
interface FundraiserProps {
  charityDetails: CharityDetails
}
const getProgressBar = (targetAmount: number, raisedAmount: number): number => {
  const percentageRaised = (raisedAmount / targetAmount) * 100
  return percentageRaised
}

const CharityFundraiserCard: FC<FundraiserProps> = ({ charityDetails }) => {
  const navigate = useNavigate()
  const { landingPageData } = charityDetails
  const trimText = (text: string, limit: number): string => {
    if (text.length <= limit) {
      return text
    }
    return text.slice(0, limit) + '...'
  }

  return (<section className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {landingPageData.fundraisers.map((fundraiser: any, index: number) => (
                <div key={index} className='border border-white bg-white' onClick={() => {
                  navigate(Navigation.fundraiserLink(fundraiser.fundraiser_id))
                }}>
                    <div className="relative">
                        {fundraiser.youtube_url && <YoutubeEmbed classname="w-full h-60" embedUrl={fundraiser.youtube_url} />}
                        {!fundraiser.youtube_url && <FundraiserImage classname='w-full h-60 object-cover' image={fundraiser.newCoverPhoto ?? fundraiser.image_path} fundraiserId={fundraiser.fundraiser_id} />}
                    </div>
                    <div className="p-4">
                        <h1 className="py-2 font-bold text-left text-gm-black md:text-3xl text-xl">{trimText(fundraiser.title, 35)}</h1>
                        <p className='text-sm text-left charity-fundraiser-description'>{fundraiser.charity_name}</p>
                        <ProgressBar className='w-full py-2' completed={getProgressBar(fundraiser.target_amount, fundraiser.raised_amount)} bgColor='#02A47B' height='3px' isLabelVisible={false} />
                        <div className="py-2 text-xs flex flex-col gap-1">
                            <p className='text-sm text-left text-gm-black'>
                                <span className='font-bold text-gm-black'>{displayTidyCurrency(fundraiser.raised_amount, fundraiser.target_currency, true)} raised</span> • {fundraiser.donation_count} donors
                            </p>
                        </div>
                        <div className="py-2">
                            <Button className="max-w-3xl h-9 md:h-auto rounded-lg flex items-center justify-center" variant="gm-share">
                                <span className="md:text-3xl text-sm">Donate</span>
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </section>
  )
}
export default CharityFundraiserCard
