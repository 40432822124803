import { useState, type FC } from 'react'
import { useQuery } from 'react-query'

import { getCharity } from '~/state/charity'

import '~/index'
import { Navigation } from '~/service/navigation'

import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Spinner } from '@lib/components'

import { CauseType, LandingPageType } from '@givematch/common'

import { type DonationPlan, useDonationPlan } from '~/hooks/useDonationPlan'
import { getFundraiser } from '~/state/fundraiser'
import { FundraiserDonation } from '~/components/fundraiser/display/FundraiserDonation'
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import { type FundraiserTracker, Tracking } from '~/service/tracking'
import { SessionStorage } from '~/service/sessionStorage'
import { useAuth } from '@lib/hooks'
import { type Currency } from '@lib/services'
import { GreetPopup } from '../../GreetPopup'
import { useExperiment } from '@lib/react-ab-test'
import { ActiveExperiments, ExplainerPageRemovedVariants } from '~/splitTesting/splittesting'
import { isNonApprovedAPICharity } from '~/utils/charityAPI'
import { ModalBtn, ModalNonApprovedAPICharity } from '~/components/ModalNonApprovedAPICharity'
import { shouldUseTempFeature } from '../temp-charity-feature/TempCharityFeature'

export interface ReusePopupProps {
  donorFirstName?: string
  donorLastName?: string
  currency?: Currency | string
  causeDonation?: Record<string, number>
}
interface FundraiserProps extends ReusePopupProps {
  fundraiserID: string
  referrerCharityId?: string
}
interface GreetPopupProps extends ReusePopupProps {
  modalShow: boolean
  setModalShow: (status: boolean) => void
}
interface FundraiserComponentProps extends FundraiserProps {
  fundraiserID: string
}
const Fundraiser: FC<FundraiserComponentProps> = ({ fundraiserID, referrerCharityId, ...ReusePopupProps }) => {
  const { pathname } = useLocation()
  const { user } = useAuth()
  const { donation, setCharity, setDonation } = useDonationPlan()
  const navigate = useNavigate()
  const { chainCode } = useParams() as { chainCode: string }
  const [showGreetModal, setShowGreetModal] = useState(true)
  const [showNotApprovedModal, setShowNotApprovedModal] = useState(false)
  const { activeVariant: explainerPageRemovedVariant } = useExperiment(ActiveExperiments.ExplainerPageRemoved)
  const { isLoading: fundraiserLoading, isError: fundraiserError, data: fundraiser } = useQuery(['fundraiser', fundraiserID, donation.donationCurrency], async () => getFundraiser(fundraiserID, donation.donationCurrency), {
    onSuccess: (data) => {
      const currentFundraiser: FundraiserTracker = {
        fundraiser_organiser_name: `${data.organiser.first_name} ${data.organiser.last_name}`,
        fundraiser_id: data.fundraiser_id,
        fundraiser_name: data.title
      }
      sessionStorage.setItem(SessionStorage.currentFundraiser, JSON.stringify(currentFundraiser))
    }
  })
  const { isLoading: charityLoading, isError: charityError, data: charity } = useQuery(['charity', fundraiser?.charity_id], async () => getCharity(fundraiser!.charity_id), {
    enabled: fundraiser && fundraiser.charity_id !== '',
    onSuccess: (charity) => {
      setCharity(charity)
      if (referrerCharityId) {
        sessionStorage.setItem(SessionStorage.referrerCharityKey, JSON.stringify({
          name: charity.name,
          id: charity.charity
        }))
      }
      Tracking.pageViewedWithTracking(pathname, user?.email)
    }
  })
  const shouldUseTemp = shouldUseTempFeature(fundraiser?.charity_id)

  const onDonateButtonClick = (amount: number): void => {
    // API Charity not approved
    if (isNonApprovedAPICharity(charity ?? donation.charity)) {
      setShowNotApprovedModal(true)
      Tracking.selectAPICharityNotApproved(charity ?? donation.charity, donation.amount, pathname, user?.email)
      return
    }

    const newDonation: DonationPlan = {
      ...donation,
      amount,
      eventID: '',
      fundraiserTitle: fundraiser!.title,
      fundraiserID,
      causeType: CauseType.FUNDRAISER_CHARITY,
      charity: charity ?? donation.charity,
      causeAmounts: {},
      landingPage: LandingPageType.Fundraiser
    }
    Tracking.donateFundraiserButton(amount, donation.donationCurrency)
    // Experiment amount page
    if (amount === 0) {
      setDonation({ ...newDonation, matchedAmount: 0 })
      navigate(Navigation.donationAmountPage())
      return
    }

    setDonation(newDonation)
    // Regular flow
    if (!shouldUseTemp && explainerPageRemovedVariant === ExplainerPageRemovedVariants.present) {
      navigate(Navigation.explainer())
    } else {
      navigate(Navigation.payment())
    }
  }
  const greetPopupProps: GreetPopupProps = {
    ...ReusePopupProps,
    modalShow: showGreetModal,
    setModalShow: () => setShowGreetModal(false)
  }
  return <div className="lg:min-h-screen w-full flex flex-col">
    <Header/>
    {!fundraiserLoading && !fundraiserError && !charityLoading && !charityError && fundraiser && charity && (
      <FundraiserDonation
        fundraiser={fundraiser}
        onDonateButtonClick={onDonateButtonClick}
        eventID={fundraiser.fundraiser_id}
        charity={charity}
        isPreview={false}
      />
    )}
    {chainCode && showGreetModal && <GreetPopup {...greetPopupProps} />}
    {(fundraiserLoading || charityLoading) && <Spinner className="m-auto" />}
    {(fundraiserError || charityError) && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch fundraiser or charity data </p>}

    {showNotApprovedModal && (
      <ModalNonApprovedAPICharity
        showModal={showNotApprovedModal}
        setShowModal={setShowNotApprovedModal}
        charityName={charity?.name ?? ''}
        cta1={(
          <ModalBtn
            primary={true}
            onClick={() => { setShowNotApprovedModal(false); navigate(Navigation.home()) }}
          >
            Select another charity
          </ModalBtn>
        )}
      />
    )}
    <Footer />
  </div>
}

export default Fundraiser
