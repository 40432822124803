import { SelectMedia } from '../SelectMedia'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'
import { type FundraiserStepComponent } from './types'
import { useState } from 'react'

type FRProperties = Pick<FundraiserPlan, 'coverPhoto' | 'youtubeUrl'>

export const SelectFRCoverPhoto: FundraiserStepComponent<FRProperties> = ({
  partial,
  next
}) => {
  const [coverPhoto, setCoverPhoto] = useState(partial.coverPhoto)
  const [youtubeUrl, setYoutubeUrl] = useState(partial.youtubeUrl)

  // TODO remove passig state setter directly to child component
  return (
    <SelectMedia
      coverPhoto={coverPhoto}
      youtubeEmbedUrl={youtubeUrl}
      setCoverPhoto={setCoverPhoto}
      setYoutubeEmbedUrl={setYoutubeUrl}
      onProceed={() => next({ coverPhoto, youtubeUrl })}
      onDelete={() => {
        setCoverPhoto('')
        setYoutubeUrl('')
      }}
    />
  )
}
