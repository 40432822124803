import {
  type ChangeEventHandler,
  type Dispatch,
  type FC,
  type MouseEventHandler,
  type SetStateAction,
  useRef,
  useState
} from 'react'
import selectPhoto from '~/assets/select-photo.svg'
import youtubeIcon from '~/assets/youtube.svg'
import { FundraiserDefaultImages } from '~/components/fundraiser/FundraiserDefaultImages'
import { EditPhoto } from '~/components/EditPhoto'
import { YoutubeUrlInput } from '~/components/YoutubeUrlInput'
import { EditedImageVideo } from '~/components/EditedImageVideo'
import { GivematchFundraiserTestIds } from '@lib/testing'
import { isFileSizeInMbValid } from '@givematch/common'
import { validMimeType } from '@lib/services'

interface props {
  onProceed: () => void
  onDelete: () => void
  coverPhoto: string
  youtubeEmbedUrl: string
  setCoverPhoto: Dispatch<SetStateAction<string>>
  setYoutubeEmbedUrl: Dispatch<SetStateAction<string>>
}
export const SelectMedia: FC<props> = ({
  onProceed,
  onDelete,
  setCoverPhoto,
  setYoutubeEmbedUrl,
  coverPhoto,
  youtubeEmbedUrl
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [imageSrc, setImageSrc] = useState('')
  const [youtubeInput, setYoutubeInput] = useState(false)
  const [imageErr, setImageErr] = useState(false)

  const handleSelectPhoto: MouseEventHandler = (_: React.MouseEvent<HTMLButtonElement>) => {
    setImageErr(false)
    hiddenFileInput.current!.click()
  }

  const handleUploadYoutubeLink: MouseEventHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(event)
    setYoutubeInput(true)
  }

  const handleFileSelected: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      if (!isFileSizeInMbValid(event.target.files[0], 5)) {
        setImageErr(true)
      } else {
        const reader = new FileReader()
        reader.addEventListener('load', () => setImageSrc(reader.result?.toString() ?? ''))
        reader.readAsDataURL(event.target.files[0])
      }
    }
  }

  const onDeleteClick = (): void => {
    setImageSrc('')
    setCoverPhoto('')
    setYoutubeInput(false)
    setYoutubeEmbedUrl('')
    onDelete()
  }

  const MediaOptions: FC = () => {
    return <>
      <FundraiserDefaultImages setImageSrc={setImageSrc}/>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileSelected}
        accept={validMimeType.join(',')}
        className='hidden'
      />
      <button
        className="w-full flex flex-col rounded h-36 bg-gm-gray-100 justify-center items-center mt-5"
        onClick={handleSelectPhoto}
        data-test-id={GivematchFundraiserTestIds.uploadPhotoButton}
      >
        <img src={selectPhoto} alt="Select cover pic" />
        <span className="font-light">Upload your photo</span>
      </button>
      {imageErr && <span className='text-gm-red font-sm'> * please select an image less than 5mb</span>}
      <button
        className="w-full flex flex-col rounded h-36 bg-gm-gray-100 justify-center items-center mt-5"
        onClick={handleUploadYoutubeLink}
      >
        <img src={youtubeIcon} alt="Youtube" />
        <span className="font-light">Upload a YouTube link (Optional)</span>
      </button>
    </>
  }
  const getSelectMediaOptions = (): React.ReactNode => {
    return (
      <>
        <span className="text-gm-green-500 font-semibold mt-5 text-xl">Using a bright and clear photo helps people connect to your fundraiser right away.</span>
        {(coverPhoto || youtubeEmbedUrl) && <EditedImageVideo coverPhoto={coverPhoto} youtubeEmbedUrl={youtubeEmbedUrl} onEdit={onDeleteClick} onDelete={onDeleteClick} onContinueButton={onProceed}/>}
        {(!coverPhoto && !youtubeEmbedUrl) && <MediaOptions/> }
      </>
    )
  }

  const getEditPhotoLayout = (): React.ReactNode => {
    return (
      <>
        {imageSrc && <EditPhoto imageSrc={imageSrc} setImageSrc={setImageSrc} setCoverPhoto={setCoverPhoto}/>}
        {youtubeInput && <YoutubeUrlInput setYoutubeEmbedUrl={setYoutubeEmbedUrl} setYoutubeInput={setYoutubeInput}/>}
      </>
    )
  }

  return <div>
      {(!imageSrc && !youtubeInput) && getSelectMediaOptions()}
      {(imageSrc || youtubeInput) && (!coverPhoto || !youtubeEmbedUrl) && getEditPhotoLayout()}
    </div>
}
