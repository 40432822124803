import { type FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '@lib/hooks'
import { GivematchTestId, isProd } from '@givematch/common'

import { ModalBtn, ModalNonApprovedAPICharity } from '~/components/ModalNonApprovedAPICharity'
import { Tracking } from '~/service/tracking'
import { type CharityList } from '~/state/charity'
import { isNonApprovedAPICharity } from '~/utils/charityAPI'
import angleRight from '~/assets/angle-right-white.svg'
import defaultCharityLogo from '~/assets/default-charity-logo.svg'

interface Props {
  processing: boolean
  charity: CharityList
  selected: boolean
  onCharitySelect: () => void
}

export const SelectableCharityCard: FC<Props> = ({
  charity,
  selected,
  onCharitySelect,
  processing
}) => {
  const [showModal, setShowModal] = useState(false)
  const { user } = useAuth()
  const { pathname } = useLocation()

  const select = (): void => {
    if (isNonApprovedAPICharity(charity)) {
      setShowModal(true)

      Tracking.selectAPICharityNotApproved(charity, 0, pathname, user?.email)
      return
    }
    onCharitySelect()
  }

  const logoPath = `/imageuploads/charity/${charity.charityID}/${charity.logoImage}`
  const logoPrefix = isProd() ? '' : 'https://givematch.link'

  return (
    <>
      {/* Charity Card */}
      <button
        className={`w-full flex justify-around mt-2  rounded-lg  border-2 ${selected ? 'border-gm-green' : 'border-gm-gray-300 hover:border-gm-green-200'}`}
        onClick={select}
        data-test-id={`${GivematchTestId.charityCard_}${charity.charityID}`}
        disabled={processing}
      >
        <span className="flex px-10 py-5 w-10/12">
          <img
            className="rounded w-1/6 object-contain"
            src={logoPrefix + logoPath}
            alt={`${charity.name} logo`}
            onError={(error) => (error.currentTarget.src = defaultCharityLogo)}
          />
          <span className="ml-5 flex flex-col w-5/6">
            <span
              className="text-md font-semibold"
            >
              {charity.name !== '' ? charity.name : charity.charityID}
            </span>
            <ul className="list-disc list-inside">
              {Object.values(charity.causes).map(cause => (
                <li className="text-sm font-light" key={cause.causeID}>{cause.name}</li>
              ))}
            </ul>
          </span>
        </span>
        <span className="block w-2/12 self-stretch px-1 py-2">
          <span
            className={`${selected ? 'block' : 'hidden'} bg-gm-green h-full flex justify-center items-center rounded-lg`}
          >
            <img src={angleRight} alt="right arrow" className="w-3"/>
          </span>
        </span>
      </button>

      {/* Modal */}
      {showModal && (
        <ModalNonApprovedAPICharity
          showModal={showModal}
          setShowModal={setShowModal}
          charityName={charity.name}
          cta1={(
            <ModalBtn primary={true} onClick={() => setShowModal(false)}>
              Select another charity
            </ModalBtn>
          )}
        />
      )}
    </>
  )
}
