import { type FC, useState } from 'react'
import { Navigation } from '~/service/navigation'
import { useNavigate } from 'react-router-dom'
import { useEditFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { SelectMedia } from '~/components/fundraiser/SelectMedia'
import { FundraiserLayout } from '~/components/FundraiserLayout'

export const EditCoverPhoto: FC = () => {
  const { editFundraiser, setEditFundraiser } = useEditFundraiserPlan()
  const navigate = useNavigate()
  const [coverPhoto, setCoverPhoto] = useState('')
  const [youtubeEmbedUrl, setYoutubeEmbedUrl] = useState(editFundraiser.youtube_url)

  const proceedToNextPage = (): void => {
    let imageVal: string | undefined
    let youtubeVal = ''
    let currentImagePath = editFundraiser.image_path
    if (coverPhoto !== '') {
      imageVal = coverPhoto
      youtubeVal = ''
      currentImagePath = ''
    } else {
      youtubeVal = youtubeEmbedUrl
      imageVal = undefined
      currentImagePath = ''
    }
    setEditFundraiser({ ...editFundraiser, newCoverPhoto: imageVal, image_path: currentImagePath, youtube_url: youtubeVal })
    navigate(Navigation.fundraiserSettings(editFundraiser.fundraiser_id))
  }

  const onDelete = (): void => {
    setEditFundraiser({ ...editFundraiser, image_path: '', youtube_url: '' })
  }

  return (
    <FundraiserLayout title="Add a cover photo or video">
      <SelectMedia
        onProceed={proceedToNextPage} onDelete={onDelete}
        setCoverPhoto={setCoverPhoto} setYoutubeEmbedUrl={setYoutubeEmbedUrl}
        coverPhoto={coverPhoto} youtubeEmbedUrl={youtubeEmbedUrl}
      />
    </FundraiserLayout>
  )
}
