import { Button } from '@lib/components'
import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'

export const FundraiserShareCard: FC<{ fundraiserId: string }> = ({ fundraiserId }) => {
  const navigate = useNavigate()
  return <section className="flex flex-col gap-y-4">
        <div >
            <span className="text-gm-green-500 text-sm text-justify">
                Share your fundraiser regularly with your social networks for the most success. Check in and personally ask friends to donate or share.
            </span>
        </div>
        <div >
            <Button variant="gm-secondary-md" className="w-full rounded-lg shadow-md py-3 font-bold bg-gm-yellow" onClick={() => { navigate(Navigation.shareFundraiser(fundraiserId)) }}>
                Share
            </Button>
        </div>
    </section>
}
