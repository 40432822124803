import { type FC } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getCharity } from '~/state/charity'
import { Spinner } from '@lib/components'
import Header from '~/components/Header'
import { CharityFundraiser } from '~/components/CharityFundraiser'
import Footer from '~/components/Footer'

export const CharityFundraiseLanding: FC = () => {
  const { charityID } = useParams() as { charityID: string }

  const { isLoading: charityLoading, isError: charityError, data: charity } = useQuery(
    ['charity', charityID],
    async () => getCharity(charityID)
  )

  return <div className="lg:min-h-screen w-full flex flex-col">
    <Header />
    { charityLoading && <Spinner>Loading</Spinner>}
    {!charityLoading && charityError && <>Error while loading charity</>}
    {!charityLoading && !charityError && charity && <CharityFundraiser charity={charity}/>}
    <Footer/>
  </div>
}
