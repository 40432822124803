// import { Button } from '@lib/components'
import { type FC } from 'react'

interface Message {
  id: string
  title: string
  content: string
}
interface props {
  messageType: string
}
export const FundraiserInvite: FC<props> = ({ messageType }) => {
  const notificationMessage: Message[] = [
    {
      id: '',
      title: 'Get your first donation by sharing.',
      content: 'All donations for your fundraiser will show up here. Start by inviting at least 20 people via email or text.'
    },
    {
      id: 'referral',
      title: 'You have no referred donations yet.',
      content: 'Donations you refer will show up here. Start by inviting at least 20 people via email or text.'
    }
  ]

  const message = notificationMessage.filter(msg => msg.id === messageType)[0]

  return <section>
    <div className='flex flex-col p-4 text-center'>
        <div className='font-semibold'> {message.title} </div>
        <div>
            {message.content}
        </div>
    </div>
    {/* <div className='gap-y-2 flex flex-col'>
        <Button variant='gm-primary-md' className='w-full py-3 font-bold'>
            Invite via email
        </Button>
        <Button variant='gm-primary-md' className='w-full py-3 font-bold'>
            Invite via text
        </Button>
    </div> */}
</section>
}
