import { type FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '@lib/hooks'
import { Button, EmailInput, InputWithRef, Modal, Validate, Variant } from '@lib/components'
import { GivematchFundraiserTestIds, PaymentFormTestId } from '@lib/testing'
import { type FundraiserPlan, type TeamMember } from '~/hooks/useFundraiserPlan'
import { type FundraiserStepComponent } from './types'
import { type TeamMemberForm } from '../types'
import individualIcon from '~/assets/empathy.svg'
import teamIcon from '~/assets/team.svg'
import greenCheckIcon from '~/assets/green-check.svg'
import trashIcon from '~/assets/trash_black.svg'

type FRProperties = Pick<FundraiserPlan, 'teamMembers'>

interface TeamCardProps {
  selected: boolean
  onClick: () => void
  logo: string
  title: string
  description: string
  testId: string
}

// TODO - This should semanticaly be a checkbox but can be button for now
const TeamCard: FC<TeamCardProps> = ({ selected, logo, description, onClick, title, testId }) => {
  return (
    <button
      className={`relative border-2 rounded-xl w-56 h-64 flex flex-col justify-center items-center ${selected ? 'border-gm-green' : 'border-gm-gray-300'}`}
      onClick={onClick}
      data-for-test={testId}
    >
      {selected && <img className="absolute top-2 right-1" src={greenCheckIcon} alt="green check" />}
      <img className="w-20" src={logo} alt="just me" />
      <span className="mt-2 text-gm-green font-bold text-lg">{title}</span>
      <span className="mt-2 justify-center text-center">{description}</span>
    </button>
  )
}

export const SelectFRTeamMember: FundraiserStepComponent<FRProperties> = ({
  processing,
  partial,
  next
}) => {
  const [team, setTeam] = useState(false)
  const [showTeam, setShowTeam] = useState(false)
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>(partial.teamMembers)
  const [teamMemberError, setTeamMemberError] = useState('')
  const { user } = useAuth()

  const form = useForm<TeamMemberForm>()
  const { register, handleSubmit, formState: { errors } } = form

  const onTeamClick = (): void => {
    setShowTeam(true)
    setTeam(true)
    setTeamMemberError('')
  }

  const onIndividualClick = (): void => {
    setShowTeam(false)
    setTeam(false)
    setTeamMembers([])
  }
  const addMember = (data: TeamMemberForm): void => {
    if (teamMembers.find(item => item.email === data.email)) {
      setTeamMemberError('Member already been added')
      return
    }
    if (teamMembers.length >= 5) {
      setTeamMemberError('Only maximum of 5 team members can be added')
      return
    }
    setTeamMemberError('')
    setTeamMembers([...teamMembers, {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email
    }])
    form.reset({
      firstName: '',
      lastName: '',
      email: ''
    })
  }

  const deleteMember = (member: TeamMember): void => {
    setTeamMembers([...teamMembers.filter(item => item.email !== member.email)])
  }
  const handleClose = (): void => {
    setShowTeam(false)
    teamMembers.length > 0 ? setTeam(true) : setTeam(false)
  }
  return (
    <div className="flex flex-col">
      <div className="flex gap-x-10 mt-10 justify-center">
        <TeamCard
          testId={GivematchFundraiserTestIds.justMeCard}
          selected={!team}
          onClick={onIndividualClick}
          logo={individualIcon}
          title="Just me"
          description="I will be fundraising by myself"
        />
        <TeamCard
          testId={GivematchFundraiserTestIds.teamManagementCard}
          selected={team}
          onClick={onTeamClick}
          logo={teamIcon}
          title="Team"
          description="I will be fundraising with friends"
        />
      </div>
      <Button
        data-test-id={GivematchFundraiserTestIds.continueButton}
        className="w-full mt-5"
        variant="gm-primary"
        onClick={() => next({ teamMembers })}
        disabled={processing}
      >
        <span>Continue</span>
      </Button>
      {showTeam && (
        <Modal isOpen={showTeam} onClose={handleClose}>
          <div className='p-5'>
            <p className="text-gm-green text-xl font-bold w-96">Add team member</p>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form onSubmit={handleSubmit((data) => addMember(data))}>
              <div className="flex flex-col mt-4 w-full">
                <InputWithRef
                  id="first_name"
                  data-test-id={PaymentFormTestId.firstName}
                  type="text"
                  variant={Variant.gm}
                  size={1}
                  {...register('firstName', {
                    required: 'Please provide first name',
                    validate: Validate.validateFirstName
                  })}
                  placeholder="First name *"
                />
                {errors?.firstName?.message && (
                  <p
                    data-test-id={PaymentFormTestId.firstNameError}
                    className="mt-1 text-xs text-right text-gm-red min-h-xs"
                  >
                    {errors?.firstName?.message ?? ''}
                  </p>
                )}
                <InputWithRef
                  className="mt-4 mb-4"
                  id="last_name"
                  data-test-id={PaymentFormTestId.lastName}
                  type="text"
                  variant={Variant.gm}
                  size={1}
                  {...register('lastName', {
                    validate: Validate.validateLastName
                  })}
                  placeholder="Last name"
                />
                <EmailInput
                  data-test-id={PaymentFormTestId.email}
                  variant={Variant.gm} register={register}
                  errors={errors.email}
                />
                {errors.email?.message && (
                  <p
                    data-test-id={PaymentFormTestId.emailError}
                    className="mt-1 text-xs text-right text-gm-red min-h-xs"
                  >
                    {errors.email?.message ?? ''}
                  </p>
                )}

                <Button
                  variant="gm-primary-md"
                  className="mt-5 text-sm w-3/5 mx-auto rounded-lg font-light"
                >
                  Add Team Member
                </Button>
                {teamMemberError && (
                  <p
                    className="mt-2 text-sm text-center text-gm-red min-h-xs"
                  >
                    {teamMemberError}
                  </p>
                )}
                <span
                  className="mt-3 text-gm-green font-bold text-xl"
                >
                  My fundraising team
                </span>
                <div className="mt-2 font-semibold">
                  <span>
                    {`${user!.firstName} ${user!.lastName}`}
                  </span>
                  <span className="font-extralight text-sm">
                    (Me)
                  </span>
                </div>
                {teamMembers.map(item => (
                  <div key={item.email} className="w-full flex justify-between mt-2">
                    <div className='my-auto'>
                      <span className="font-semibold">
                        {`${item.firstName} ${item.lastName}`}
                        <span className="font-extralight  text-sm">
                          ({item.email})
                        </span>
                      </span>
                    </div>
                    <img
                      className='h-6 cursor-pointer'
                      src={trashIcon}
                      onClick={() => deleteMember(item)}
                      alt="Remove"
                    />
                  </div>
                ))}
              </div>
            </form>
          </div>
        </Modal>
      )}
    </div>
  )
}
