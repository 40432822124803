import { Button } from '@lib/components'
import rightMark from '~/assets/right-mark-white.svg'
import { GivematchFundraiserTestIds } from '@lib/testing'
import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tracking } from '~/service/tracking'
import { Navigation } from '~/service/navigation'

export const CreateFundraiserNext: FC<{ fundraiserId: string }> = ({ fundraiserId }) => {
  const navigate = useNavigate()
  return (
    <div>
      <span className="text-2xl font-bold">Let's start getting donations!</span>

      <ol className="relative border-l border-gm-green-200 my-10">
        <li className="mb-10 ml-4">
          <div
            className="absolute w-5 h-5 bg-gm-green rounded-full -left-2.5 border border-white dark:border-gray-900 dark:bg-gray-700 flex justify-center items-center"
          >
              <img src={rightMark} alt='right mark'/>
          </div>
          <div className="text-gm-green">Set up your Givematch fundraiser</div>
        </li>
        <li className="mb-10 ml-4">
          <div
            className="absolute w-5 h-5 bg-gm-green-200 rounded-full -left-2.5 border border-white dark:border-gray-900 dark:bg-gray-700 flex justify-center items-center"
          />
          <span>Share with 3-5 friends and ask them to help you share your page</span>
        </li>
        <li className="mb-10 ml-4">
          <div
            className="absolute w-5 h-5 bg-gm-green-200 rounded-full -left-2.5 border border-white dark:border-gray-900 dark:bg-gray-700 flex justify-center items-center"
          />
          <span>Post your page on social media</span>
        </li>
        <li className="ml-4">
          <div
            className="absolute w-5 h-5 bg-gm-green-200 rounded-full -left-2.5 border border-white dark:border-gray-900 dark:bg-gray-700 flex justify-center items-center"
          />
          <span>Send your share link to your friends</span>
        </li>
      </ol>

      <Button
        data-test-id={GivematchFundraiserTestIds.continueButton}
        className="w-full"
        variant="gm-primary"
        onClick={() => {
          Tracking.letsGoFundraiser()
          navigate(Navigation.shareFundraiser(fundraiserId))
        }}
      >
        <span>Let's go!</span>
      </Button>
    </div>
  )
}
