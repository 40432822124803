import Header from '~/components/Header'

import Footer from '~/components/Footer'
import { type Dispatch, type FC, type SetStateAction, useState } from 'react'
import { Button, InputWithRef, Variant } from '@lib/components'
import { useForm, type UseFormReturn } from 'react-hook-form'
import { useMutation } from 'react-query'
import { createAccount, type CreateAccountFormData } from '~/state/account'
import { ComponentsTestId, GivematchFundraiserTestIds, PaymentFormTestId } from '@lib/testing'
import { RecaptchaV3 } from '@lib/services'
import sanitizeHtml from 'sanitize-html'

interface Props {
  setSignIn: Dispatch<SetStateAction<boolean>>
  setVerifyAccount: Dispatch<SetStateAction<boolean>>
  setEmail: Dispatch<SetStateAction<string>>
  track?: (fundraiserTips: boolean) => void
}

const GMOptIn: FC<{ form: UseFormReturn<CreateAccountFormData> }> = ({ form }) => {
  return <section className='border-2 border-bluegray-200 rounded-lg bg-bluegray-50'>
    <div className='flex items-center px-5 py-2'>
      <input className="w-5 h-5 border-2 border-gm-green-900 rounded mr-5 appearance-none checked:bg-gm-green-900"
        type="checkbox"
        id="optIn"
        {...form.register('fundraiser_tips_opt_in')} />
      <label htmlFor='optIn' className='text-lg cursor-pointer'>Make the most of your fundraiser</label>
    </div>

    <div className='border-t border-bluegray-200 my-2'></div>
    <label htmlFor='optIn' className='text-sm text-light'>
      <p className='px-5 py-2 cursor-pointer'>Receive tips to help you make the most of your fundraiser, and updates about important causes. Unsubscribe anytime.</p>
    </label>
  </section>
}

export const CreateAccount: FC<Props> = ({
  setSignIn,
  setVerifyAccount,
  setEmail,
  track
}) => {
  const [verifyMessage, setVerifyMessage] = useState('')
  const form = useForm<CreateAccountFormData>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      fundraiser_tips_opt_in: false,
      token: ''
    }
  })
  const { register, handleSubmit, formState } = form
  const recaptcha = new RecaptchaV3('signup')
  const { mutate: submit, isError, isLoading } = useMutation(async (data: CreateAccountFormData): Promise<void> => {
    const token = await recaptcha.getToken()
    data.token = token
    data.first_name = sanitizeHtml(data.first_name)
    data.last_name = sanitizeHtml(data.last_name)
    await createAccount(data).then((res) => {
      if (res.status === true) {
        if (track) track(data.fundraiser_tips_opt_in)
        setEmail(data.email)
        setVerifyAccount(true)
      }
    }).catch(err => {
      setVerifyMessage(err.message)
    })
  })

  return <div className="min-h-screen w-full flex flex-col items-center">
    <Header />
    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
    <form className='flex flex-col mx-5 max-w-lg md:w-1/2' onSubmit={handleSubmit((data) => submit(data))}>
      <span className='text-2xl font-bold mb-10'>Create an account to continue</span>
      <InputWithRef
        id="first_name"
        data-test-id={PaymentFormTestId.firstName}
        type="text"
        variant={Variant.gm}
        size={1}
        {...register('first_name', { required: 'Please provide your first name', validate: { isValid: (val) => sanitizeHtml(val) !== '' || 'Please enter a valid first name' } })}
        placeholder="First name" />
         {formState.errors?.first_name?.message &&
              <div className="text-sm text-right text-sz-pink" role="alert">{formState.errors.first_name.message}</div>}
      <InputWithRef
        className='mt-4'
        id="last_name"
        data-test-id={PaymentFormTestId.lastName}
        type="text"
        variant={Variant.gm}
        size={1}
        {...register('last_name', { required: 'Please provide your last name', validate: { isValid: (val) => sanitizeHtml(val) !== '' || 'Please enter a valid last name' } })}
        placeholder="Last name" />
        {formState.errors?.last_name?.message &&
              <div className="text-sm text-right text-sz-pink" role="alert">{formState.errors.last_name.message}</div>}
      <InputWithRef
        className='mt-4'
        id="email"
        data-test-id={PaymentFormTestId.email}
        type="email"
        autoComplete="email"
        variant={Variant.gm}
        size={1}
        {...register('email', { required: 'Please provide your email name' })}
        placeholder="Email address" />
      <div className='flex flex-row my-5'>
        <p>Already have an account?</p>
        <a className="px-2 text-gm-green underline cursor-pointer"
          data-test-id={ComponentsTestId.signInLink}
          onClick={() => setSignIn(true)}>Sign In</a>
      </div>
      <GMOptIn form={form} />

      <Button
        data-test-id={GivematchFundraiserTestIds.continueButton}
        className="w-full mt-10"
        variant='gm-primary'
        disabled={!formState.isDirty || isLoading} loading={isLoading}>
        <span>Continue</span>
      </Button>
      {(verifyMessage || isError) && <p className="mt-2 text-sm text-gm-red">{verifyMessage || 'Error creating account'}</p>}
      <p className='text-sm text-light py-10'>By continuing, you agree to the Givematch terms and acknowledge receipt of our privacy notice, and have read the Fundraising Regulator's guidance.</p>
    </form>
    <Footer />
  </div>
}
