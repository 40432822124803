import { CREATE_FUNDRAISER_RESTART_QUERY } from '~/components/fundraiser/create'

const charityID = ':charityID'
const aliasName = ':aliasName'
export class Navigation {
  static home (): string {
    return '/'
  }

  static charityLandingPage (id: string = charityID, alias: string = aliasName): string {
    return `/charity/${id}/${alias}`
  }

  static charityHome (id: string = charityID): string {
    return `/charity/${id}`
  }

  static charityFundraiser (charityId: string = charityID): string {
    return `/charity/fundraise/${charityId}`
  }

  static causes (id: string): string {
    return `/causes/${id}`
  }

  static selectCharity (): string {
    return '/select-charity'
  }

  static donationAmountPage (): string {
    return '/donation-amount'
  }

  static explainer (): string {
    return '/explainer'
  }

  static payment (): string {
    return '/payment'
  }

  static faqs (): string {
    return `/faqs`
  }

  static privacyPolicy (): string {
    return `/privacy-policy`
  }

  static cookiePreferences (): string {
    return `/cookie-preferences`
  }

  static termsAndConditions (): string {
    return `/terms-conditions`
  }

  static goodPractice (): string {
    return `/good-practice`
  }

  static complaintsPolicy (): string {
    return `/complaints-policy`
  }

  static aboutUs (): string {
    return `/about-us`
  }

  static newMarketingWebsite (): string {
    return `https://about.givematch.com`
  }

  static shareReferralLink (shareId: string = ':shareId', charityID: string = ':charityID'): string {
    return `/share/${shareId}${charityID !== ':charityID' ? '?charityID=' + charityID : ''}`
  }

  static fundraiserCreate (restart = false): string {
    return `/fundraiser/create${restart ? '?' + CREATE_FUNDRAISER_RESTART_QUERY + '=true' : ''}`
  }

  static fundraiserEnterLocation (): string {
    return '/fundraiser/create/select-location'
  }

  static fundraiserSelectCharity (): string {
    return '/fundraiser/create/select-charity'
  }

  static fundraiserEnterTarget (): string {
    return '/fundraiser/create/enter-target'
  }

  static fundraiserSelectCoverPhoto (): string {
    return '/fundraiser/create/select-photo'
  }

  static fundraiserEnterTitle (): string {
    return '/fundraiser/create/enter-title'
  }

  static fundraiserTeamManagementStep (): string {
    return '/fundraiser/create/team-management'
  }

  static fundraiserConfirmCharity (): string {
    return '/fundraiser/create/confirm-charity'
  }

  static fundraiserNextSteps (): string {
    return '/fundraiser/create/next-steps'
  }

  static shareFundraiser (fundraiserId: string = ':fundraiserId'): string {
    return `/fr/share/${fundraiserId}`
  }

  static linkChainCode (chainCode: string = ':chainCode'): string {
    return `/link/${chainCode}`
  }

  static fundraiserLink (fundraiserID: string = ':fundraiseID'): string {
    return `/fr/${fundraiserID}`
  }

  static fundraiserJoinInviteLink (fundraiserID: string = ':fundraiserID', inviteCode: string = ':inviteCode'): string {
    return `/fr/${fundraiserID}/join/${inviteCode}`
  }

  static fundraiserDashboard (): string {
    return `/fundraiser/dashboard`
  }

  static fundraiserDetails (fundraiserID: string = ':fundraiserID'): string {
    return `/fundraiser/${fundraiserID}/details`
  }

  static fundraiserDonations (fundraiserID: string = ':fundraiserID'): string {
    return `/fundraiser/${fundraiserID}/donations`
  }

  static fundraiserSettings (fundraiserID: string = ':fundraiserID'): string {
    return `/fundraiser/${fundraiserID}/settings`
  }

  static fundraiserTeam (fundraiserID: string = ':fundraiserID'): string {
    return `/fundraiser/${fundraiserID}/team`
  }

  static fundraiserPreview (fundraiserID: string = ':fundraiserID'): string {
    return `/fundraiser/${fundraiserID}/settings/preview`
  }

  static fundraiserEditCoverPhoto (fundraiserID: string = ':fundraiserID'): string {
    return `/fundraiser/${fundraiserID}/edit-photo`
  }

  static donorDashboard (): string {
    return `/donor/dashboard`
  }

  static donorFundraiserDetails (shareCode: string = ':shareCode'): string {
    return `/donor/${shareCode}/details`
  }

  static login (): string {
    return `/login`
  }

  static ramadanHome (): string {
    return '/ramadan'
  }

  static ramadanSelectCharity (): string {
    return '/ramadan/select-charity'
  }

  static ramadanSelectCauses (): string {
    return '/ramadan/select-causes'
  }

  static matchFundPartners (): string {
    return '/partners/matchfunders'
  }

  static charityPartner (): string {
    return '/partners/charities'
  }

  static corporatePartner (): string {
    return '/partners/corporates'
  }

  static leaderboardDetailsById (leaderboardID: string = ':leaderboardID'): string {
    return `/leaderboards/${leaderboardID}/details`
  }

  static leaderboardDetailsBySlug (slug: string = ':slug'): string {
    return `/leaderboards/${slug}`
  }
}
