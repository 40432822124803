import { Spinner, TabHeader, TabPanel } from '@lib/components'
import { type FC, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { FundraiserEdit } from '~/components/fundraiser/manage/FundraiserEdit'
import { FundraiserSetting } from '~/components/fundraiser/manage/FundraiserSetting'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { getCharity } from '~/state/charity'
import { getFundraiserByDonor } from '~/state/fundraiser'

export const Settings: FC = () => {
  const [index, setIndex] = useState(0)
  const { fundraiserID } = useParams() as { fundraiserID: string }

  const { isLoading: fundraiserLoading, isError: fundraiserError, data: fundraiser } = useQuery(['fundraiser', fundraiserID], async () => getFundraiserByDonor(fundraiserID))
  const { isLoading: charityLoading, isError: charityError, data: charity } = useQuery(['charity', fundraiser?.charity_id], async () => getCharity(fundraiser!.charity_id), {
    enabled: !!fundraiser
  })
  return <FundraiserLayout title="Edit your fundraiser">
        {(fundraiserLoading || charityLoading) && <Spinner className="m-auto" />}
        {(fundraiserError || charityError) && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch fundraiser or charity data </p>}
        {!fundraiserLoading && !fundraiserError && !charityLoading && !charityError && fundraiser && charity &&
        <div>
            <div className='flex flex-row gap-x-8 my-4'>
                <TabHeader index={0} currentIndex={index} title="Edit" onClick={() => setIndex(0)} />
                <TabHeader index={1} currentIndex={index} title="Settings" onClick={() => setIndex(1)} />
            </div>
            <TabPanel index={0} currentIndex={index}>
                <FundraiserEdit fundraiser={fundraiser} charity={charity} />
            </TabPanel>
            <TabPanel index={1} currentIndex={index}>
                <FundraiserSetting fundraiser={fundraiser} />
            </TabPanel>
        </div>
        }
    </FundraiserLayout>
}
