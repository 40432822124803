import { type FC, useState } from 'react'
import { CreateAccount } from '~/pages/CreateAccount'
import Login from '~/pages/Login'
import { VerifyAccount } from '~/pages/VerifyAccount'

interface Props {
  loginPage?: boolean
  track?: {
    verifyAccount?: () => void
    createAccount?: (fundraiserTips: boolean) => void
  }
}

export const LoginAndCreateAccount: FC<Props> = ({ loginPage, track }) => {
  const [signIn, setSignIn] = useState(loginPage ?? false)
  const [verifyAccount, setVerifyAccount] = useState(false)
  const [email, setEmail] = useState('')
  return (
    <>
      {signIn && <Login setSignIn={setSignIn}/>}
      {!signIn && !verifyAccount && (
        <CreateAccount
          setSignIn={setSignIn}
          setVerifyAccount={setVerifyAccount}
          setEmail={setEmail}
          track={(tips) => {
            if (track?.createAccount) track.createAccount(tips)
          }}
        />
      )}
      {!signIn && verifyAccount && (
        <VerifyAccount
          email={email}
          emailUnEditable
          track={() => {
            if (track?.verifyAccount) track.verifyAccount()
          }}
        />
      )}
    </>
  )
}
