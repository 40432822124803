import { Button, InputWithRef, uploadImageBase64, Variant } from '@lib/components'
import { type FC, useState } from 'react'
import imageLogo from '../../../assets/image.svg'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { type Charity } from '~/state/charity'
import { type editFundRaiserData } from '~/data/donationFormData'
import { useForm } from 'react-hook-form'
import CurrencyInput from 'react-currency-input-field'
import { displayTidyCurrency, penniesToPound } from '@lib/services'
import { YoutubeEmbed } from '../../YoutubeEmbed'
import { useEditFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { FundraiserImage } from '../FundraiserCard'
import { useMutation } from 'react-query'
import type Fundraiser from '~/state/fundraiser'
import { updateFundraiser } from '~/state/fundraiser'
import { Tracking } from '~/service/tracking'
import { Editor } from '../Editor'

interface props {
  fundraiser: Fundraiser
  charity: Charity
}

export const FundraiserEdit: FC<props> = ({ fundraiser, charity }) => {
  const { editFundraiser, setEditFundraiser, resetEditFundraiser } = useEditFundraiserPlan()
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate()
  const form = useForm<editFundRaiserData>({
    defaultValues: {
      title: editFundraiser.title !== '' ? editFundraiser.title : fundraiser.title,
      story: editFundraiser.story !== '' ? editFundraiser.story : fundraiser.story,
      target_amount: editFundraiser.target_amount > 0 ? penniesToPound(editFundraiser.target_amount) : penniesToPound(fundraiser.target_amount) ?? '',
      newCoverPhoto: editFundraiser.newCoverPhoto,
      image_path: editFundraiser.newCoverPhoto ? undefined : editFundraiser.image_path !== '' ? editFundraiser.image_path : fundraiser.image_path,
      youtube_url: editFundraiser.newCoverPhoto ? undefined : editFundraiser.youtube_url !== '' ? editFundraiser.youtube_url : fundraiser.youtube_url,
      fundraiser_id: editFundraiser.fundraiser_id !== '' ? editFundraiser.fundraiser_id : fundraiser.fundraiser_id
    }
  })

  const { handleSubmit, register, getValues, setValue } = form
  const editFundraiserEvent = (): void => {
    Tracking.trackEditFundraiser(fundraiser)
    handleSubmit(async () => completeFundraiserUpdate.mutate())
  }
  const [story, setStory] = useState(getValues('story'))
  const onStoryValueChange = (value: string): void => {
    setStory(value)
    setValue('story', value)
  }

  const convertToPenny = (currentAmount: string): number => {
    const amount = currentAmount.replace(/[^0-9.]/g, '') // strip away the currency symbol and comma
    const amountInPenny = Number(amount) * 100 // convert to penny

    return amountInPenny
  }
  const saveProgressOnFundraiser = (): void => {
    const amountInPenny = convertToPenny(getValues('target_amount').toString())
    setValue('target_amount', amountInPenny)
    setEditFundraiser({ ...editFundraiser, ...getValues(), donation_count: fundraiser.donation_count, donations: fundraiser.donations, raised_amount: fundraiser.raised_amount, organiser: fundraiser.organiser, charity_name: fundraiser.charity_name })
  }
  const onPreviewClick = (): void => {
    saveProgressOnFundraiser()
    navigate(Navigation.fundraiserPreview(fundraiser.fundraiser_id))
  }
  const changeCoverMedia = (): void => {
    saveProgressOnFundraiser()
    navigate(Navigation.fundraiserEditCoverPhoto(fundraiser.fundraiser_id))
  }

  const completeFundraiserUpdate = useMutation(async () => {
    // prepare record for update
    setProcessing(true)
    setError('')
    // save to state first, in case anything happened
    saveProgressOnFundraiser()
    const editedFundRaiserData: editFundRaiserData = getValues()

    const newCoverPhoto = editedFundRaiserData.newCoverPhoto || ''
    editedFundRaiserData.newCoverPhoto = ''
    const isBase64: boolean = newCoverPhoto.includes('base64')
    editedFundRaiserData.new_image_upload = isBase64

    // send record to the api
    const fundraiserResponse = await updateFundraiser(editedFundRaiserData.fundraiser_id, editedFundRaiserData)
    // check if its an image not a youtube link and it has a base64 data type
    if (isBase64) {
      // then delete the exiting image ?

      // then upload the new one
      await uploadImageBase64(newCoverPhoto, fundraiserResponse.uploadUrl, 'fundraiserImage')
    }
    return fundraiserResponse
  }
  , {
    onSuccess: () => {
      // reset the state
      resetEditFundraiser()
      // navigate to the details page of the fundraiser
      navigate(Navigation.fundraiserDetails(fundraiser.fundraiser_id))
    },
    onError: () => {
      setError('We\'re sorry there has been a problem updating your fundraiser')
      setProcessing(false)
    }
  }
  )
  return <section >
        {/* eslint-disable @typescript-eslint/no-misused-promises */}
        <form id="EditFundraiserForm" onSubmit={handleSubmit(async () => completeFundraiserUpdate.mutate())} className="flex flex-col gap-y-8 my-4">
            {/* eslint-enable @typescript-eslint/no-misused-promises */}
            {/* Title and goal */}
            <div className="flex flex-col gap-y-3">
                <div className="flex flex-col gap-y-2">
                    <h3 className="text-gm-green-500 font-semibold"> Title and goal </h3>

          <InputWithRef
            variant={Variant.gm}
            size={1}
            type="text"
            placeholder="Fundraiser Title"
            id="title"
            {...register('title', {
              required: 'Please provide the title'
            })}
          />
          <p className="text-xs">A good title starts with an action word and includes a name</p>
        </div>

        <div className="flex flex-col gap-y-2">
          <CurrencyInput
            intlConfig={{ locale: 'ja-JP', currency: charity.currency }} // 'ja-JP' works for all currencies whereas en-UK and en-US don't
            defaultValue={getValues('target_amount')}
            id="target_amount"
            className=" border-gm-green w-full p-4 border-2 rounded-md text-left"
            placeholder={displayTidyCurrency(0, charity.currency)}
            step={0.01}
            {...register('target_amount', {
              required: 'Please provide the target amount'
            })}
          />
          <p className="text-xs">You can change your goal</p>
        </div>
      </div>

      {/* Cover Media  */}
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-row justify-between">
          <h3 className="text-gm-green font-semibold"> Cover Media </h3>
          <Button onClick={() => changeCoverMedia()} variant="gm-secondary-md" className="text-xs border-gm-green text-gm-green">
            <div className="flex flex-row justify-between items-center gap-x-1">
              <img src={imageLogo} alt="edit" className="w-3 h-3 flex align-middle " />
              <span> Change </span>
            </div>
          </Button>
        </div>
        <div className="flex flex-col gap-y-2">
          {getValues().youtube_url && <YoutubeEmbed classname="rounded-xl w-full md:h-96" embedUrl={getValues().youtube_url} />}
          {!getValues().youtube_url && <FundraiserImage classname='rounded-xl w-full' image={getValues().newCoverPhoto ?? getValues().image_path} fundraiserId={fundraiser.fundraiser_id} />}
          <p className="text-xs">Use a photo or video that represents your story well. A photo (JPG, PNG, GIF, or BMP) should be less than 5MB.</p>
        </div>
      </div>

      {/* Story  */}
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-row justify-between">
          <h3 className="text-gm-green font-semibold"> Story </h3>
        </div>
        <div className="flex flex-col gap-y-4 clear-both" data-color-mode="light">
        <Editor story={story} setStory={onStoryValueChange} />
          <p className="text-xs">Tell donors why support is needed and how funds will be used.</p>
        </div>
      </div>

      {/* More Details  */}
      {/* <div className="flex flex-col gap-y-4">
                <div className="flex flex-row justify-between">
                    <h3 className="text-gm-green font-semibold"> More details </h3>
                </div>
                <div>
                    <InputWithRef
                        variant={Variant.gm}
                        size={1}
                        type="text"
                        placeholder="Fundraiser URL"
                    />
                    <p className="text-xs text-sz-gray-300">givematch.com/f/jvyac2-myfundraiser</p>
                </div>
                <SelectWithRef
                    className="flex-1 block w-full p-4 bg-white border rounded border-gm-green"
                    variant={Variant.gm}
                >
                    {types.map((c) => <option key={c.code} value={c.code}>{c.name}</option>)}
                </SelectWithRef>
                <InputWithRef
                    variant={Variant.gm}
                    size={1}
                    type="text"
                    placeholder="N17 5HD"
                    id="address"
                    {...register('address', {
                        required: 'Please provide the address',
                    })}
                />
            </div> */}

      {/* Action Buttons  */}
      <div className="flex flex-col gap-y-4">
        <Button variant="gm-primary-md" className="w-full" disabled={processing} onClick={() => editFundraiserEvent()}>
          Save
        </Button>

        <Button disabled={processing} variant="gm-secondary-md" className="w-full py-2 px-3 font-semibold text-xl" onClick={() => onPreviewClick()} >
          Preview Fundraiser
        </Button>
      </div>
      <div>
        {error !== '' && <p
          className="p-4 text-sm text-right text-gm-red">{error}</p>}
      </div>
    </form>
  </section>
}
