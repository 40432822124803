import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { type User } from '@lib/hooks'
import { uploadImageBase64 } from '@lib/components'
import { isProd } from '@givematch/common'
import { type FundraiserPlan, getDefaultFundraiserPlan } from '~/hooks/useFundraiserPlan'
import {
  createFundraiserDraft,
  type FundraiserDraftCreateOrUpdateResponse,
  getFundraiserDraft,
  publishFundraiserDraft,
  updateDraftFundraiser
} from '~/state/fundraiser'
import {
  frDraftToPlan,
  getDraftUpdatePayload,
  recaptchaFundraiserDraftToken
} from './utils'
import { Tracking } from '~/service/tracking'

interface HookReturn {
  fundraiser: FundraiserPlan
  updateFundraiser: (fr: FundraiserPlan) => Promise<boolean>
  publishedId: string
  publishFundraiser: (withUpdate?: FundraiserPlan) => Promise<void>
  error: string
  processing: boolean
}

const imagePrefix = (): string => isProd() ? '' : 'https://givematch.link'
export const tempFundraiserImagePath =
  (id: string): string => `${imagePrefix()}/imageuploads/fundraiser/${id}.jpg`

export const useCreateFundraiser = (user: User | null, useDraft = false): HookReturn => {
  const [fundraiser, setFundraiser] = useState(getDefaultFundraiserPlan())
  const [publishedId, setPublishedId] = useState('')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (user && useDraft) {
      getFundraiserDraft().then((fundraisers) => {
        if (fundraisers.length === 0) return
        const plan = frDraftToPlan(fundraisers[0])
        setFundraiser(plan)
      }).catch(() => {})
    }
  }, [user, useDraft])

  const uploadPhoto = async (photo: string, url: string, id: string): Promise<void> => {
    await uploadImageBase64(photo, url, 'fundraiserImage')
    setFundraiser({
      ...fundraiser,
      coverPhoto: tempFundraiserImagePath(id),
      new_image_upload: false
    })
  }

  const updateFundraiser = async (fr: FundraiserPlan, chained = false): Promise<boolean> => {
    if (!user) {
      setFundraiser(fr)
      return true
    }

    try {
      setError('')
      if (chained) setProcessing(true)
      let response: FundraiserDraftCreateOrUpdateResponse
      if (!fr.fundraiser_id) {
        const token = await recaptchaFundraiserDraftToken('create')
        response = await createFundraiserDraft({ ...fr, coverPhoto: '' }, token)
      } else {
        const token = await recaptchaFundraiserDraftToken('update')
        const draftEdit = getDraftUpdatePayload(fr.fundraiser_id, { ...fr, coverPhoto: '' })
        response = await updateDraftFundraiser(draftEdit, token)
      }
      setFundraiser({
        ...fr,
        fundraiser_id: response.fundraiser_id,
        draft_updated_time: response.draft_updated_time
      })
      if (fr.new_image_upload) {
        await uploadPhoto(fr.coverPhoto, response.uploadUrl, response.fundraiser_id)
      }
      return true
    } catch (e) {
      console.error(e)
      setError('We\'re sorry there has been a problem with setting your fundraiser')
      return false
    } finally {
      if (chained) setProcessing(false)
    }
  }

  // TODO - Update publish api to take updates to fundraiser so updateFundraiser doesn't have to be called first
  const completeFundraiser = useMutation(async (usr: User) => {
    setError('')
    const token = await recaptchaFundraiserDraftToken('publish')
    const published = await publishFundraiserDraft(
      fundraiser.teamMembers,
      token
    )
    return { published, usr }
  }, {
    onSuccess: async ({ published, usr }) => {
      Tracking.completeFundraiser(
        { ...fundraiser, is_campaign: published.is_campaign },
        fundraiser.charity_opt_in,
        usr
      )
      setPublishedId(published.fundraiser_id)
      setProcessing(false)
    },
    onError: (e) => {
      console.error(e)
      setError('We\'re sorry there has been a problem with setting your fundraiser')
      setProcessing(false)
    }
  })
  const publishFundraiser = async (withUpdate?: FundraiserPlan): Promise<void> => {
    if (!user) return
    setProcessing(true)
    let canContinue = true
    if (withUpdate) {
      canContinue = await updateFundraiser(withUpdate, true)
    }
    if (canContinue) completeFundraiser.mutate(user)
  }

  return {
    fundraiser,
    updateFundraiser,
    publishedId,
    publishFundraiser,
    error,
    processing
  }
}
