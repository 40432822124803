import '~/index'
import { Spinner } from '@lib/components'
import { type FC } from 'react'
import { useQuery } from 'react-query'
import { getAllFundraiser } from '~/state/fundraiser'
import { FundraiserCard } from '../FundraiserCard'

export const FundraiserList: FC = () => {
  const {
    isLoading: fundraiserLoading,
    isError: fundraiserError,
    data: fundraisers
  } = useQuery(['fundraisers'], async () => getAllFundraiser())
  return <section className="py-4 grid md:grid-cols-2  grid-cols-1 gap-4">
    {(fundraiserLoading) && <Spinner className="m-auto"/>}
    {(fundraiserError) && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch your fundraiser data </p>}
    {!fundraiserLoading && !fundraiserError && !fundraisers &&
      <p className="px-4 mt-4 text-gm-red"> No fundraisers available </p>
    }
    {!fundraiserLoading && !fundraiserError && fundraisers?.map(fundraiser => (
      <FundraiserCard fundraiser={fundraiser} key={fundraiser.fundraiser_id} isDetails={false}/>
    ))}
  </section>
}
