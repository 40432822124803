import { type FC, useCallback, useState } from 'react'

import { getCroppedImg } from '~/service/canvadUtil'
import Cropper from 'react-easy-crop'
import '~/css/fundraiser.css'
import rotate from '~/assets/refresh.svg'
import { GivematchFundraiserTestIds } from '@lib/testing'

interface props {
  imageSrc: string
  setImageSrc: React.Dispatch<React.SetStateAction<string>>
  setCoverPhoto: React.Dispatch<React.SetStateAction<string>>
}

export const EditPhoto: FC<props> = ({ imageSrc, setImageSrc, setCoverPhoto }) => {
  const [crop, setCrop] = useState({ x: 10, y: 10 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      setCoverPhoto(croppedImage!.toDataURL('image/jpg'))
      setImageSrc('')
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation, setCoverPhoto, setImageSrc])

  const onRotateClick = (): void => {
    setRotation((rotation + 90) % 360)
  }
  return <div className="flex flex-col w-auto pb-10 md:max-w-lg md:w-full mt-10">

    <div className='relative w-full h-96 bg-gm-black'>
      <div className="grid grid-cols-3 mx-5 absolute -top-8 left-0 right-0 ">
        <button
          className='col-start-1 underline hover:no-underline'
          onClick={() => setImageSrc('')}
        >
          Cancel
        </button>
        <span className="flex text-xl font-bold justify-center col-start-2">Edit Photo</span>
        {/* eslint-disable @typescript-eslint/no-misused-promises */}
        <button className="flex underline hover:no-underline text-gm-green col-start-3 justify-end cursor-pointer"
          onClick={async () => showCroppedImage()}
          data-test-id={GivematchFundraiserTestIds.editPhotoDoneButton}
        >
          Done
        </button>
        {/* eslint-enable @typescript-eslint/no-misused-promises */}
      </div>
      <div className="absolute top-14 left-0 right-0 bottom-14">
        <Cropper
          image={imageSrc}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={7 / 4}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    </div>

    <div className='flex justify-between items-center mr-5'>
      <img src={rotate} alt='Rotate' className='w-1/6 p-5' onClick={() => onRotateClick()}/>
      <div className='flex w-5/6 justify-center items-center border-l-2 border-gm-gray-300 pl-5'>
        <p className='text-2xl font-extralight cursor-pointer' onClick={() => setZoom(zoom - 0.1)}>-</p>
        <input type="range" name="range" className="mx-5 w-full h-2 bg-gm-gray-200 rounded-lg appearance-none cursor-pointer " value={zoom}
               min={1} max={3} step={0.1} aria-labelledby="Zoom"
               onChange={(e) => {
                 setZoom(parseFloat(e.target.value))
               }}/>
        <span className='text-2xl font-extralight cursor-pointer' onClick={() => setZoom(zoom + 0.1)}>+</span>
      </div>
    </div>
  </div>
}
