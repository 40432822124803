import { type FC, useState } from 'react'
import giftaid from '~/assets/gift-aid-logo.svg'
import infoIcon from '~/assets/icons/info-tool-tip.svg'

interface Props {
  id: string
  checked: boolean
  onChange: (checked: boolean) => void
}
const GiftAidBanner: FC<Props> = ({ id, checked, onChange }) => {
  const [showInfo, setShowInfo] = useState(false)
  return (
    <div className="flex flex-col gap-4 p-4 rounded-lg bg-gm-gray-50 border border-gm-gray-200">
      <div className="flex gap-4 justify-between items-center">
        <label className='flex gap-10 items-center flex-grow'>
          <input
            id={id}
            className="w-5 h-5 border border-gm-green-900 rounded appearance-none checked:bg-gm-green-900"
            type="checkbox"
            onChange={(e) => onChange(e.target.checked)}
            defaultChecked={checked}
          />
          <img src={giftaid} alt={'Giftaid logo'} className='h-10' />
        </label>
        <button
          type='button'
          onClick={() => setShowInfo(!showInfo)}
          className='flex items-center justify-end'
        >
          <img src={infoIcon} alt={'info'} className='text-current h-6' />
        </button>
      </div>
      {showInfo && (
        <label htmlFor={id} className="text-xs">
          By selecting yes, I declare that I am a UK taxpayer. I understand that if I pay less Income
          Tax and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on
          my donations I must pay the difference. I am happy for Mercy Mission to claim Gift Aid on
          this donation and on prior donations I have made in the past to Mercy Mission where
          applicable. Mercy Mission reserves the right to use third party service providers to collect
          gift aid on its behalf. Where necessary, your personal data may be shared with your chosen
          charity or our third-party providers in order to process your gift aid. For more information,
          please see our Privacy Policy.
          <br/><br/>
          Where Mercy Mission deems it appropriate, Mercy Mission may use all or parts of this
          GiftAid to cover costs from 3rd party partners, including but not limited to fundraisers,
          donor advised fund fees and/or other third party providers who help facilitate or promote
          donations. This helps keep costs lower for charities, since the costs may be taken from
          the gift aid and thereby maintaining a free platform for your chosen charity and therefore
          furthers Mercy Mission's charitable objects. If you do not agree with this, you should
          not select yes.
        </label>
      )}
    </div>
  )
}

export default GiftAidBanner
