import { useState } from 'react'
import { Button, CountrySelection, InputWithRef, Variant } from '@lib/components'
import { type Country, localeCountries } from '@lib/services'
import { GivematchFundraiserTestIds } from '@lib/testing'
import { type FundraiserStepComponent } from './types'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'

type FRProperties = Pick<FundraiserPlan, 'country' | 'postcode'>

export const EnterFRLocation: FundraiserStepComponent<FRProperties> = ({
  partial,
  next,
  processing
}) => {
  const [country, setCountry] = useState(partial.country)
  const [postcode, setPostcode] = useState(partial.postcode)
  return (
    <div className="pt-5">
      <label
        className="text-gm-green-500 font-semibold text-xl"
        htmlFor="postcode"
      >
          Your Location
      </label>
      <CountrySelection
        className="mt-5 flex-1 block w-full p-4 bg-white border rounded border-gm-green"
        countries={Object.values(localeCountries)}
        value={partial.country}
        onCountryChange={(v: Country) => {
          setCountry(v)
        }}
        variant="gm"
      />

      <InputWithRef
        id="postcode"
        className="w-full mt-2"
        data-test-id={GivematchFundraiserTestIds.postCode}
        autoComplete="postal-code"
        type="text"
        variant={Variant.gm}
        size={1}
        defaultValue={postcode}
        onChange={(event) => setPostcode(event.target.value)}
        placeholder="Postcode"
      />

      <Button
        className="w-full mt-10 shadow-2xl"
        data-test-id={GivematchFundraiserTestIds.continueButton}
        variant="gm-primary"
        onClick={() => next({ country, postcode })}
        disabled={!postcode || processing}>
        <span className="">Continue</span>
      </Button>
    </div>
  )
}
