import { type FC } from 'react'
import gmLogo from '~/assets/logo.svg'
import { Navigation } from '~/service/navigation'
import logoIcon from '~/assets/logo_icon_dark.svg'
import { Button } from '@lib/components'
import { type Charity } from '~/state/charity'
import { useNavigate } from 'react-router-dom'
import { FundraiserCard } from '~/components/fundraiser/FundraiserCard'
import defaultCharityLogo from '~/assets/default-charity-logo.svg'

interface props {
  charity: Charity
}

export const CharityFundraiser: FC<props> = ({ charity }) => {
  const navigate = useNavigate()

  return <div className={'flex flex-col justify-center items-center w-full px-5'}>
    <div className={'flex flex-col justify-center items-center md:w-full mt-10 md:mt-20'}>
      <span className={'text-3xl md:text-5xl text-gm-green font-bold'}>
        Givematch Now partners with <span className={'text-gm-black'}>{charity.name}</span>
      </span>
      <div className="flex w-full justify-center mt-10">
        <img className="w-32" src={gmLogo} alt="Givematch" />
        <img className="w-32 h-32 object-contain" src={`/imageuploads/charity/${charity.charity}/${charity.logo_image}`} alt="Givematch" onError={(error) => (error.currentTarget.src = defaultCharityLogo)}/>
      </div>

      <p className="flex text-xl font-semibold mt-10">Support {charity.name}</p>
      <Button
        className="w-80 rounded-lg shadow-md mt-10"
        variant="gm-primary"
        onClick={() => navigate(Navigation.charityHome(charity.charity))}>
        <div className="flex items-center justify-center">
          <span className="font-semibold">
            Donate
          </span>
        </div>
      </Button>
      <p className="flex text-xl font-semibold mt-10">Or</p>
      <Button
        className="w-96 rounded-lg shadow-md mt-10"
        variant="gm-share"
        onClick={() => navigate(Navigation.fundraiserCreate(true))}>
        <div className="flex items-center justify-center">
          <img className="w-6 mr-5" src={logoIcon} alt="Givematch" />
          <span className="font-semibold">
            Start your fundraiser
          </span>
        </div>
      </Button>
    </div>
    { charity.fundraisers && <>
        <p className='text-xl mt-10'>Donate to popular fundraisers</p>
        <div className='flex flex-col md:flex-row md:flex-wrap items-center my-10 gap-x-5 gap-y-5'>
          { charity.fundraisers?.map(fundraiser => <FundraiserCard isDetails={true} fundraiser={fundraiser} charity={charity} className='w-96'/>) }
        </div>
      </>
    }
  </div>
}
