import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'
import { charityDafPartnerDetails, Country } from '@lib/services'
import { type FundraiserStepComponent } from './types'
import defaultCharityLogo from '~/assets/default-charity-logo.svg'
import { useState } from 'react'
import { GivematchFundraiserTestIds } from '@lib/testing'
import { Button } from '@lib/components'

type FRProperties = Pick<FundraiserPlan, 'charity' | 'charity_opt_in' | 'country'>
type NextArguments = Pick<FundraiserPlan, 'charity_opt_in'>

export const ConfirmNewFR: FundraiserStepComponent<FRProperties, NextArguments> = ({
  processing,
  partial,
  next
}) => {
  const [optIn, setOptIn] = useState(partial.charity_opt_in)

  const daf = charityDafPartnerDetails.get((partial.charity.country || partial.country) as Country)!

  const proceed = (): void => {
    next({ charity_opt_in: optIn })
  }
  return (
    <div>
      <div>
        <h2 className="text-gm-green-500 font-semibold mt-5 text-xl">
          Money raised will support
        </h2>
        <div className="flex mt-5 px-10 py-5 rounded-lg border-2 border-gm-green">
          <img
            src={`/imageuploads/charity/${partial.charity.charityID}/${partial.charity.logoImage}`}
            className="rounded w-1/6 object-contain" alt={`${partial.charity.name} logo`}
            onError={(error) => (error.currentTarget.src = defaultCharityLogo)}
          />
          <div className='ml-5 flex flex-col w-5/6'>
            <span className='text-md font-semibold'>
              {partial.charity.name === '' ? partial.charity.charityID : partial.charity.name}
            </span>
            <ul className='list-disc list-inside'>
              {Object.values(partial.charity.causes).map((cause, i) => (
                <li
                  key={i}
                  className='text-xs font-light'
                >
                  {cause.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <section>
        <h3 className="mt-5 font-bold">
          This means you will not personally withdraw money from our fundraisers.
        </h3>
        <p className="mt-5">
          Your donations will be received by The Givematch Fund held at {daf.name},
          a registered charity (No. {daf.number}), will receive the donations and grant the
          funds to your chosen charity via bank transfer or cheque.
        </p>
        <p className="mt-5">
          Charities will typically receive the donations from {daf.name} within 15 - 45 days
          of your donation if the charity has enrolled with {daf.name} &#40;or 90 days if the charity
          has not yet enrolled&#41;.{' '}
          {
            (partial.charity.country as Country) === Country.GB &&
            'Unlike other platforms, 100% of any Gift Aid will go to the charity as well.'
          }
        </p>
        <p className="mt-5">
          In very rare cases, a recommended charity may cease to meet {daf.name}'s requirements to
          receive funds. In such cases, {daf.name} will reassign the funds to another charity
          and &#40;wherever possible&#41; seek a new recommendation from the donor, in accordance
          with its policies.
        </p>
        <p className="mt-5">
          If the charity you are fundraising for requests your contact information, Givematch may
          provide it to the charity to contact you about the fundraiser you started on its behalf,
          and for other legally permissible uses. By continuing, you agree to Givematch's terms
          and {daf.name}'s terms.
        </p>
      </section>

      <span className="border-t border-gm-green my-5" />
      <div>
        <p>
          By launching your fundraiser, you understand donors will donate to and receive tax
          receipts from {daf.name}.
        </p>
        <label className="flex items-center gap-3 px-5 py-4 border-2 border-bluegray-200 rounded-lg bg-bluegray-50 mt-5 cursor-pointer">
          <input
            id="inform"
            className="block w-5 h-5 border-2 border-gm-green-900 rounded appearance-none checked:bg-gm-green-900"
            type="checkbox"
            checked={optIn}
            onChange={() => setOptIn(!optIn)}
          />
          <span
            className="flex-1 text-xs"
          >
            {`I'm happy for ${partial.charity.name} to inform me by email about more ways that I can help and the impact of my fundraising.`}
          </span>
        </label>
      </div>

      <Button
        data-test-id={GivematchFundraiserTestIds.completeFundraiserButton}
        className="w-full mt-5"
        variant="gm-primary"
        onClick={proceed}
        disabled={processing}
      >
        <span>Complete Fundraiser</span>
      </Button>
    </div>
  )
}
