import { Button } from '@lib/components'
import { type FC } from 'react'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { Navigation } from '~/service/navigation'
import { GivematchTestId } from '@givematch/common'
import { useNavigate } from 'react-router-dom'
import logoIcon from '../../assets/plus.svg'
import { FundraiserList } from '~/components/fundraiser/manage/FundraiserList'
import { Tracking } from '~/service/tracking'

export const Dashboard: FC = () => {
  const navigate = useNavigate()
  const startFundraiser = (): void => {
    Tracking.startYourFundraiser()
    navigate(Navigation.fundraiserCreate())
  }
  return <FundraiserLayout title="Your Fundraisers">
        {/* Create a new Fundraiser CTA */}
        <div className='border border-l-0 border-r-0 border-t-0 border-gm-green-300 py-4'>
            <Button
                className="w-full mt-5 rounded-lg shadow-md"
                variant='gm-primary'
                data-test-id={GivematchTestId.chooseCauseButton}
                onClick={() => startFundraiser()}>
                <div className='flex items-center justify-center'>
                    <img className="w-4 mr-1" src={logoIcon} alt="Givematch" />
                    <span className='text-lg'>
                        Start a new fundraiser
                    </span>
                </div>

            </Button>
        </div>

        {/* Fund Raiser List goes here  */}

        <FundraiserList />

    </FundraiserLayout>
}
