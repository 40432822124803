import { type FC, useState } from 'react'
import { InputWithRef, Variant } from '@lib/components'
import { GivematchFundraiserTestIds } from '@lib/testing'

const youtubeUrlParser = (url: string): string => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return (match && match[7].length === 11) ? match[7] : ''
}

interface props {
  setYoutubeEmbedUrl: (data: string) => void
  setYoutubeInput: (data: boolean) => void
}
export const YoutubeUrlInput: FC<props> = ({ setYoutubeEmbedUrl, setYoutubeInput }) => {
  const [youtubeUrl, setYoutubeUrl] = useState('')
  const extractYouTubeID = (): void => {
    setYoutubeEmbedUrl(`https://www.youtube.com/embed/${youtubeUrlParser(youtubeUrl)}`)
    setYoutubeInput(false)
    setYoutubeUrl('')
  }

  return <div className="flex flex-col w-auto mx-5 pb-10 md:max-w-lg md:w-full">
    <div className="flex justify-between">
      <button onClick={() => setYoutubeInput(false)}>Cancel</button>
      <span className="flex text-xl font-bold justify-center">YouTube URL</span>
      <button onClick={extractYouTubeID}>Done</button>
    </div>
    <InputWithRef
      id="youtubeUrl"
      className="mt-2 w-full"
      data-test-id={GivematchFundraiserTestIds.youtubeUrl}
      type="text"
      variant={Variant.gm}
      size={1}
      onChange={(event) => setYoutubeUrl(event.target.value)}
      placeholder="Youtube Url" />

  </div>
}
