import { type FC, useState } from 'react'
import { Button, InputWithRef, Variant } from '@lib/components'
import {
  type Currency,
  displayTidyCurrency,
  getCurrencySymbol,
  penniesToPound
} from '@lib/services'
import { GivematchFundraiserTestIds } from '@lib/testing'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'
import { type FundraiserStepComponent } from './types'
import rightArrow from '~/assets/right-arrow-green.svg'

type FRProperties = Pick<FundraiserPlan, 'target_amount' | 'charity'>
type NextArguments = Pick<FundraiserPlan, 'target_amount'>

const TargetMatchBox: FC<{ target: number, currency: Currency }> = ({
  target,
  currency
}) => {
  return (
    <div
      className={`flex flex-col border-2 border-gm-green rounded-xl mt-10 px-2 py-2 shadow-xl`}
    >
      <div className='flex justify-center gap-x-4 flex-wrap'>
        <span
          className='text-3xl font-bold text-gm-green'
        >
          {displayTidyCurrency(target, currency)}
        </span>
        <img className='w-9' src={rightArrow} alt='right arrow'/>
        <span className='text-3xl font-bold black-border-text'>
          {displayTidyCurrency(target * 2, currency)}
        </span>
      </div>
      <div
        className='mt-4 font-semibold'
      >
        <p>
          When you're all done, don't forget to share your fundraiser!
        </p>
        <p className='mt-4'>
          Try and get as many people as you can to donate and share your fundraiser, so you can raise as much as you can and make as much impact as possible!
        </p>
      </div>
    </div>
  )
}

const DEFAULT_TARGET_AMOUNT_PENNIES = 100000

export const EnterFRTargetAmount: FundraiserStepComponent<FRProperties, NextArguments> = ({
  processing,
  partial,
  next
}) => {
  const [target, setTarget] = useState(partial.target_amount || DEFAULT_TARGET_AMOUNT_PENNIES)

  const onValueChange = (value?: string): void => {
    const targetAmount = Math.max(0, parseFloat(value ?? '0') * 100)
    setTarget(targetAmount)
  }
  return (
    <div>
      <div className='relative'>
        <span className='absolute text-5xl font-bold text-gm-green top-3 left-3'>{getCurrencySymbol(partial.charity.currency)}</span>
        <InputWithRef
          id="target_amount"
          className={`${getCurrencySymbol(partial.charity.currency).length === 1 ? 'px-14' : 'px-32'} text-4xl font-bold text-gm-green w-full`}
          data-test-id={GivematchFundraiserTestIds.targetAmount}
          type="number"
          variant={Variant.gm}
          size={1}
          defaultValue={penniesToPound(target) ?? ''}
          step={0.01}
          onChange={(event) => onValueChange(event.target.value)}
          placeholder="Starting Goal" />
        <span className='absolute text-lg font-semibold text-gm-green top-5 right-3'>{partial.charity.currency.toUpperCase()}</span>
      </div>

      <span className="mt-2 text-md">
        You can always change your goal as you go.
      </span>

      <Button
        data-test-id={GivematchFundraiserTestIds.continueButton}
        className="w-full mt-5 shadow-2xl"
        variant="gm-primary"
        disabled={!target || processing}
        onClick={() => next({ target_amount: target })}
      >
        <span>Continue</span>
      </Button>

      {target <= 0 && (
        <TargetMatchBox
          target={target}
          currency={partial.charity.currency}
        />
      )}

      <div className="mt-10 px-5 py-4 text-sm bg-bluegray-100">
        <p className="mb-4">
          Donations will be granted to your chosen charity so you don't have to worry about withdrawing the money.
        </p>
        <p>
          Transaction fees are deducted from each donation.
        </p>
      </div>
    </div>
  )
}
