import { useState } from 'react'
import { Button, InputWithRef, Variant } from '@lib/components'
import { GivematchFundraiserTestIds } from '@lib/testing'
import { Editor } from '~/components/fundraiser/Editor'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'
import { type FundraiserStepComponent } from './types'

type FRProperties = Pick<FundraiserPlan, 'title' | 'story' | 'charity'>
type NextArguments = Pick<FundraiserPlan, 'title' | 'story'>
interface Props {
  showPreview: (args: NextArguments) => void
}

const defaultTitle = (charityName: string): string => `Help me in raising funds for ${charityName}`
const defaultStory = (charityName: string): string => `
  Want to join me in making a difference? I'm raising money to benefit ${charityName}, and any donation will help make an impact. Thanks in advance for your contribution to this cause that means so much to me.
`

export const EnterFRTitle: FundraiserStepComponent<FRProperties, NextArguments, Props> = ({
  processing,
  partial,
  next,
  showPreview
}) => {
  const [title, setTitle] = useState(partial.title ?? defaultTitle(partial.charity.name))
  const [story, setStory] = useState(partial.story ?? defaultStory(partial.charity.name))

  return (
    <div>
      <div>
        <span className="text-gm-green-500 font-semibold mt-5 mb-5 text-xl">
          Give your fundraiser a title
        </span>
        <div className="relative">
          <InputWithRef
            id="title"
            data-test-id={GivematchFundraiserTestIds.titleText}
            type="text"
            variant={Variant.gm}
            size={1}
            defaultValue={title}
            onChange={(event) => setTitle(event.target.value)}
            placeholder="Donate to help..."
            maxLength={50}
            className='pr-5 w-full'
          />
          <span className='absolute top-4 right-2 font-light text-sm'>
            {50 - title.length}
          </span>
        </div>
      </div>

      <div>
        <span className="text-gm-green-500 font-semibold mt-5 text-xl">
          Tell your story:
        </span>
        <p className="my-5">
          Introduce yourself and what you're raising funds for.
          Describe why it's important to you. Explain how the funds will be used.
        </p>
        <div data-color-mode="light mx-4 h-64">
          <Editor story={story} setStory={setStory} />
          <p className="text-xs">
            Tell donors why support is needed and how funds will be used.
          </p>
        </div>
      </div>

      <Button
        data-test-id={GivematchFundraiserTestIds.continueButton}
        className="w-full mt-5"
        variant="gm-primary"
        disabled={!title || !story || processing}
        onClick={() => next({ title, story })}
      >
        <span>Continue</span>
      </Button>

      <Button
        variant="gm-secondary-md"
        className="w-full py-2 my-4 px-3 font-semibold text-xl"
        onClick={() => showPreview({ title, story })}
      >
        Preview Fundraiser
      </Button>
    </div>
  )
}
