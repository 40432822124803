import { type FC } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { configure } from 'mobx'
import { observer } from 'mobx-react'

import { ProvideAuth } from '@lib/hooks'
import { DonationPlanProvider } from '~/hooks/useDonationPlan'
import { EditFundRaiserProvider } from '~/hooks/useFundraiserPlan'

import { SplittestingStore } from '@lib/services'
import { experimentDebugger } from '@lib/react-ab-test'
import { defaultVariants } from '~/splitTesting/splittesting'

import { hasActiveGivematchCampaign } from './service/activeCampaign'
import { Tracking } from '~/service/tracking'
import { isLive } from '~/service/isLive'
import { Navigation } from '~/service/navigation'

import { PageViewEvents, ScrollToTop, Spinner } from '@lib/components'
import AuthenticatedRoute from '~/components/AuthenticatedRoute'
import { FundraiserCardDetails } from './components/fundraiser/manage/FundraiserCardDetails'
import TrackShareLinkIFrame from './components/TrackShareLinkIFrame'
import { PaymentContainer } from '~/components/PaymentContainer'
import { CookieLayout } from './components/CookierBanner'

import ErrorBoundary from './error'

// General pages
import HomePage from './pages/Home'
import CausesPage from './pages/Causes'
import PaymentPage from './pages/Payment'
import SharePage from './pages/SharePage'
import ExplainerPage from './pages/Explainer'
import ShareLandingPage from './pages/ShareLanding'
import CharityLandingPage from './pages/CharityLanding'
import PrivacyPolicyPage from './pages/PrivacyPolicy'
import GivematchSplashPage from './pages/GivematchSplash'
import AboutUsPage from './pages/AboutUs'
import Error from './pages/Error'
import FAQPage from './pages/FAQs'
import CookiePreferencesPage from './pages/CookiePreferences'
import UnsubscribePage from './pages/Unsubscribe'
import Contact from '~/pages/Contact'
import SelectCharity from '~/pages/SelectCharity'
import CharityHomePage from './pages/CharityHomePage'
import { CharityFundraiseLanding } from '~/pages/CharityFundraiseLanding'
import { FundraiserInvite } from '~/pages/FundraiserInvite'
import { ComplaintsPolicy } from './pages/ComplaintsPolicy'
import { GoodPractice } from './pages/GoodPractice'
import { TermsAndConditions } from './pages/TermsAndConditions'
import { ShareLinksWidget } from './pages/ShareLinks'

// Partner pages
import MatchFundPartner from './pages/MatchFundPartner'
import CharityPartner from './pages/CharityPartner'
import CorporatePartner from './pages/CorporatePartner'

// Fundraiser pages
import FundraiserLanding from '~/pages/fundraiser/FundraiserLanding'
import { Dashboard } from './pages/fundraiser/Dashboard'
import { CreateFundraiser, CreateFundraiserRedirect } from './pages/fundraiser/CreateFundraiser'
import { Share } from '~/pages/fundraiser/Share'
import { Donations } from './pages/fundraiser/Donations'
import { Settings } from './pages/fundraiser/Settings'
import { PreviewFundraiser } from './pages/fundraiser/PreviewFundraiser'
import { EditCoverPhoto } from './pages/fundraiser/EditCoverPhoto'
import { Team } from '~/pages/fundraiser/Team'
import { DonationAmountPage } from './pages/fundraiser/DonationAmountPage'

// Donor pages
import { DonorDashboard } from './pages/donor/DonorDashboard'
import { DonationDetails } from './pages/donor/DonationDetails'

// Ramadan pages
import { RamadanHome } from '~/pages/ramadan/RamadanHome'
import SelectCharityRamadan from '~/pages/ramadan/RamadanSelectCharity'
import SelectCausesRamadan from '~/pages/ramadan/SelectCausesRamadan'

// Leaderboard pages
import LeaderboardLanding from '~/pages/leaderboards/LeaderboardLanding'
import RootLeaderboardsLoader from '~/components/leaderboard/RootLeaderboardsLoader'

if (!isLive) {
  experimentDebugger.enable()
}

configure({
  enforceActions: 'never'
})

Tracking.initialize()

const queryClient = new QueryClient()

interface AppProps {
  readonly splittestingStore: SplittestingStore
}

const splitTestingStore = new SplittestingStore(defaultVariants)
void splitTestingStore.load()

const App: FC<AppProps> = observer(({ splittestingStore }) => {
  if (!hasActiveGivematchCampaign()) {
    return <Router>
      <ScrollToTop />
      <CookieLayout>
        <Routes>
          <Route path={Navigation.faqs()} element={<FAQPage />} />
          <Route path={Navigation.privacyPolicy()} element={<PrivacyPolicyPage />} />
          <Route path={Navigation.aboutUs()} element={<AboutUsPage />} />
          <Route path={Navigation.cookiePreferences()} element={<CookiePreferencesPage />} />
          <Route path={Navigation.termsAndConditions()} element={<TermsAndConditions />} />
          <Route path="/unsubscribe/:shareCode" element={<UnsubscribePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/good-practice" element={<GoodPractice />} />
          <Route path="/complaints-policy" element={<ComplaintsPolicy />} />
          <Route path={Navigation.matchFundPartners()} element={<MatchFundPartner />} />
          <Route element={<GivematchSplashPage />} />
        </Routes>
      </CookieLayout>
    </Router>
  }

  return <>
    {splittestingStore.isLoading && <div className="flex justify-center mt-20"><Spinner className="text-gm-green-500" /></div>}
    {!splittestingStore.isLoading && !splittestingStore.error &&
      <ErrorBoundary component={<Error errorMessage="Something went wrong" />}>
        <QueryClientProvider client={queryClient}>
          <ProvideAuth>
            <DonationPlanProvider>
              <EditFundRaiserProvider>
                <Router>
                  <ScrollToTop />
                  <CookieLayout>
                    <Routes>
                      <Route path={Navigation.faqs()} element={<FAQPage />} />
                      <Route path={Navigation.privacyPolicy()} element={<PrivacyPolicyPage />} />
                      <Route path={Navigation.aboutUs()} element={<AboutUsPage />} />
                      <Route path={Navigation.cookiePreferences()} element={<CookiePreferencesPage />} />
                      <Route path={Navigation.termsAndConditions()} element={<TermsAndConditions />} />

                      <Route path={Navigation.complaintsPolicy()} element={<ComplaintsPolicy />}/>

                      <Route path={Navigation.goodPractice()} element={<GoodPractice />}/>

                      <Route path={Navigation.home()} element={ <HomePage />}/>

                      <Route path={Navigation.charityFundraiser()} element={ <CharityFundraiseLanding />}/>

                      <Route path={Navigation.charityLandingPage()} element={ <CharityHomePage />}/>

                      <Route path={Navigation.charityHome()} element={<CharityLandingPage />}/>

                      <Route path="/causes/:charityID" element={<CausesPage />}/>

                      <Route path={Navigation.selectCharity()} element={<SelectCharity />}/>

                      <Route
                        path={Navigation.donationAmountPage()}
                        element={<DonationAmountPage />}
                      />

                      <Route path="/explainer" element={<ExplainerPage />}/>

                      <Route path={Navigation.payment()} element={<PaymentContainer>
                          <PaymentPage />
                        </PaymentContainer>}/>

                      <Route path="/complete/:shareId" element={<SharePage />}/>

                      <Route path="/share/:shareId" element={<ShareLinksWidget />}/>

                      <Route path="/charityShare/:shareId" element={<>
                        <TrackShareLinkIFrame />
                        <ShareLinksWidget />
                        </>}/>

                      <Route path={Navigation.linkChainCode()} element={<ShareLandingPage />}/>

                      <Route path="/unsubscribe/:shareCode" element={<UnsubscribePage />}/>

                      {/*
                          We have a duplicate page here on a different URL due to changing it mid-campaign to make GA reporting easier.
                          We need the old URLs to slowly die but whilst they are dying we need both URLs to be active
                      */}
                      <Route path="/contact" element={<Contact />}/>

                      <Route path={Navigation.matchFundPartners()} element={ <MatchFundPartner />}/>

                      <Route path={Navigation.charityPartner()} element={<CharityPartner />}/>

                      <Route path={Navigation.corporatePartner()} element={ <CorporatePartner />}/>

                      <Route path={Navigation.fundraiserCreate()} element={<CreateFundraiser />} />

                      <Route path={Navigation.fundraiserEnterLocation()} element={<CreateFundraiserRedirect />}/>
                      <Route path={Navigation.fundraiserSelectCharity()} element={<CreateFundraiserRedirect />}/>
                      <Route path={Navigation.fundraiserEnterTarget()} element={<CreateFundraiserRedirect />}/>
                      <Route path={Navigation.fundraiserSelectCoverPhoto()} element={<CreateFundraiserRedirect />} />
                      <Route path={Navigation.fundraiserEnterTitle()} element={<CreateFundraiserRedirect />} />
                      <Route path={Navigation.fundraiserTeamManagementStep()} element={<CreateFundraiserRedirect />} />
                      <Route path={Navigation.fundraiserConfirmCharity()} element={<CreateFundraiserRedirect />} />
                      <Route path={Navigation.fundraiserNextSteps()} element={<CreateFundraiserRedirect />} />

                      <Route path={Navigation.fundraiserDashboard()} element={<AuthenticatedRoute >
                        <Dashboard />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.fundraiserDetails()} element={<AuthenticatedRoute >
                        <FundraiserCardDetails />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.fundraiserTeam()} element={<AuthenticatedRoute >
                        <Team />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.fundraiserDonations()} element={<AuthenticatedRoute >
                        <Donations />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.fundraiserSettings()} element={<AuthenticatedRoute >
                        <Settings />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.fundraiserEditCoverPhoto()} element={<AuthenticatedRoute >
                        <EditCoverPhoto />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.fundraiserPreview()} element={<AuthenticatedRoute >
                        <PreviewFundraiser />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.donorDashboard()} element={<AuthenticatedRoute >
                        <DonorDashboard />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.donorFundraiserDetails()} element={<AuthenticatedRoute >
                        <DonationDetails />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.login()} element={<AuthenticatedRoute loginPage={true}>
                        <DonorDashboard />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.shareFundraiser()} element={<Share />} />

                      <Route path={Navigation.fundraiserJoinInviteLink()} element={<AuthenticatedRoute >
                        <FundraiserInvite />
                      </AuthenticatedRoute>} />
                      <Route path={Navigation.fundraiserLink()} element={<FundraiserLanding />} />

                      {/* Leaderboards */}
                      <Route path={Navigation.leaderboardDetailsById()} element={<LeaderboardLanding />} />

                      <Route path={Navigation.leaderboardDetailsBySlug()} element={<RootLeaderboardsLoader />} />

                      <Route path={Navigation.ramadanHome()} element={<RamadanHome />} />

                      <Route path={'ramadan.html'} />
                      <Route path={Navigation.ramadanSelectCharity()} element={<SelectCharityRamadan />} />

                      <Route path={Navigation.ramadanSelectCauses()} element={<SelectCausesRamadan />} />

                      <Route path="/:chainCode" element={<ShareLandingPage />} />

                    </Routes>
                  </CookieLayout>
                  <PageViewEvents tracking={Tracking} trackable={Tracking.isTrackableUrl} eventPropsFunctions={[Tracking.getCampaignDetails, Tracking.getFundraiserExtraData, Tracking.getCharityExtraData]} />
                </Router>
              </EditFundRaiserProvider>
            </DonationPlanProvider>
          </ProvideAuth>
        </QueryClientProvider>
      </ErrorBoundary>
    }
  </>
})

const AppContainer: FC = () => {
  return <App splittestingStore={splitTestingStore} />
}

export default AppContainer
