import { type FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { ShareCodeError } from '~/state/shareDetails'
import { ShareSocialMediaLinks } from '~/components/ShareSocialMediaLinks'
import { Spinner } from '@lib/components'
import { getFundraiser } from '~/state/fundraiser'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { getFundraiserShareMessage, tempCharityShareMessage } from '~/service/shareMessage'
import {
  shouldUseTempFeature,
  TempCharityFeature
} from '~/components/fundraiser/temp-charity-feature/TempCharityFeature'

export const Share: FC = () => {
  const { fundraiserId } = useParams() as { fundraiserId: string }

  const [fundraiserTitle, setFundraiserTitle] = useState('')
  const [charityId, setCharityId] = useState('')
  const [charityName, setCharityName] = useState('')
  const [organiserName, setOrganiserName] = useState('')
  const [error, setError] = useState('')

  const shareUrl = `${window.location.origin}?fr=${fundraiserId}`

  const { isLoading: fundraiserLoading } = useQuery(['fundraiser', fundraiserId], async () => getFundraiser(fundraiserId), {
    onSuccess: (res) => {
      setFundraiserTitle(res.title)
      setCharityId(res.charity_id)
      setCharityName(res.charity_name)
      setOrganiserName(`${res.organiser.first_name} ${res.organiser.last_name}`)
    },
    onError: (err) => {
      if (err instanceof ShareCodeError) {
        setError('The fundraiser code appears to be invalid')
      } else {
        setError('Unable to retrieve fundraiser details.')
      }
    }
  })

  const useTemp = shouldUseTempFeature(charityId)
  const shareMessage = useTemp
    ? tempCharityShareMessage(fundraiserTitle, shareUrl)
    : getFundraiserShareMessage(organiserName, charityName, shareUrl, charityId)

  return <FundraiserLayout title="Share your fundraiser">
    <span className="text-gm-green-500 font-semibold my-5 text-md">
      The more people you share your fundraiser with
      , <TempCharityFeature
        usual={'the more chance there is to double your donation goal'}
        temp={'the more the fundraiser raises'}
        charityId={charityId}
      />!
    </span>
    {fundraiserLoading && <Spinner className="mx-auto" />}
    {!fundraiserLoading && error !== '' && <p className="mt-4 text-center text-gm-red">
      {error}
    </p>
    }
    {!fundraiserLoading && error === '' && <ShareSocialMediaLinks charityID={charityId} shareUrl={shareUrl} shareMessage={shareMessage} />}
  </FundraiserLayout>
}
