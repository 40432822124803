import { type FC, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useAuth } from '@lib/hooks'
import { GMSubDomains, getFullDomainURL } from '@givematch/common'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'
import closeIcon from '../assets/xmark.svg'
import logo from '../assets/new_logo.svg'
// import { useTranslation } from 'react-i18next'
// i18n implementation
// import i18n from "i18next"
// import { Select } from '@lib/components'

enum ALIGN {
  LEFT,
  RIGHT
}

interface MenuItem {
  title: string
  url: string
  onClick?: () => void
  authRequired?: boolean | undefined
  align: ALIGN
  external?: boolean
}

interface HeaderProps {
  transparent?: boolean
  className?: string
  menuItemClassName?: string
}

const Header: FC<HeaderProps> = ({ transparent, className, menuItemClassName }) => {
  // const { t } = useTranslation('global')

  const { user, logout } = useAuth()
  const [active, setActive] = useState(false)

  const inverted = transparent

  // Menu handlers
  function onLogoutClick (): void {
    Tracking.logOut(user?.email)
    logout()
  }

  function onMyDonationsClick (): void {
    Tracking.myDonations(user?.email)
  }

  function onMyFundraisersClick (): void {
    Tracking.myFundraiser(user?.email)
  }

  function charityDashboardClick (): void {
    Tracking.charityDashboard(user?.email)
  }

  function onLogInClick (): void {
    Tracking.loginClick()
  }

  // Menu items
  const menuItems: MenuItem[] = [
    {
      title: 'HOME',
      url: '/',
      align: ALIGN.LEFT
    },
    {
      title: 'CREATE A FUNDRAISER',
      url: Navigation.fundraiserCreate(true),
      align: ALIGN.LEFT
    },
    {
      title: 'CHARITY LOGIN',
      url: getFullDomainURL(GMSubDomains.charity, true),
      onclick: charityDashboardClick,
      align: ALIGN.LEFT,
      external: true
    },
    {
      title: 'OUR STORY',
      url: Navigation.newMarketingWebsite(),
      align: ALIGN.LEFT,
      external: true
    },
    {
      title: 'MY FUNDRAISERS',
      url: Navigation.fundraiserDashboard(),
      onClick: onMyFundraisersClick,
      authRequired: true,
      align: ALIGN.RIGHT
    },
    {
      title: 'MY DONATIONS',
      url: Navigation.donorDashboard(),
      onClick: onMyDonationsClick,
      authRequired: true,
      align: ALIGN.RIGHT
    },
    {
      title: 'LOG OUT',
      url: '/',
      onClick: onLogoutClick,
      authRequired: true,
      align: ALIGN.RIGHT
    },
    {
      title: 'LOG IN',
      url: Navigation.login(),
      onClick: onLogInClick,
      authRequired: false,
      align: ALIGN.RIGHT
    }
  ].filter(item => {
    // Items that show everytime
    if (item.authRequired === undefined) {
      return true
    }

    return user === null ? !item.authRequired : item.authRequired
  })

  // i18n implementation
  // const handleChangeLanguage = (lang: string) => {
  //   i18n.changeLanguage(lang)
  // }

  return (
    <>
      <header
        className={[
          'py-4 px-4 sm:px-20 gap-x-3 md:px-12 lg:px-40 flex items-center justify-between',
          `${className ?? ''} w-full`
        ].join(' ')}
      >
        {/* Logo */}
        <NavLink to="/">
          <img className="lg:w-52 w-24" src={logo} alt="Givematch"/>
        </NavLink>

        {/* Menu Bar - hidden on mobile */}
        <nav className="hidden md:flex justify-between w-full text-xs">
          {/* Menu Items - Left */}
          <div
            className={`${user === null ? 'md:pl-10 lg:pr-46 xl:pr-48' : 'lg:px-14 xl:px-20'} flex flex-row flex-grow justify-between items-start gap-6`}>
            {/* eslint-disable @typescript-eslint/no-unused-expressions */}
            {menuItems.filter(menuItem => menuItem.align === ALIGN.LEFT).map((menuItem, mId) =>
              menuItem.external ? (
                <a
                  className={`font-semibold transition-colors duration-500 md:px-2 ${menuItemClassName ?? ''}`}
                  href={menuItem.url}
                  key={`${mId}-${menuItem.title.replace(' ', '-')}-${menuItem.url}`}
                  onClick={() => menuItem.onClick ? menuItem.onClick() : null}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* {t(menuItem.title)} */}
                  {menuItem.title}
                </a>
              ) : (
                <Link
                  className={`font-semibold transition-colors duration-500 md:px-2 ${menuItemClassName ?? ''}`}
                  to={menuItem.url}
                  key={`${mId}-${menuItem.title.replace(' ', '-')}-${menuItem.url}`}
                  onClick={() => menuItem.onClick ? menuItem.onClick() : null}
                >
                  {/* {t(menuItem.title)} */}
                  {menuItem.title}
                </Link>
              )
            )}
            {/* eslint-enable @typescript-eslint/no-unused-expressions */}
          </div>

          {/* Menu Items - Right */}
          <div className="flex flex-row items-end gap-1 text-gm-newgreen">
            {/* eslint-disable @typescript-eslint/no-unused-expressions */}
            {menuItems.filter(menuItem => menuItem.align === ALIGN.RIGHT).map((menuItem, mId) =>
              menuItem.external ? (
                <a
                  className={`font-semibold transition-colors duration-500 md:mx-2 ${menuItemClassName ?? ''}`}
                  href={menuItem.url}
                  key={`${mId}-${menuItem.title.replace(' ', '-')}-${menuItem.url}`}
                  onClick={() => menuItem.onClick ? menuItem.onClick() : null}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* {t(menuItem.title)} */}
                  {menuItem.title}
                </a>
              ) : (
                <Link
                  className={`font-semibold transition-colors duration-500 md:mx-2 ${menuItemClassName ?? ''}`}
                  to={menuItem.url}
                  key={`${mId}-${menuItem.title.replace(' ', '-')}-${menuItem.url}`}
                  onClick={() => menuItem.onClick ? menuItem.onClick() : null}
                >
                  {/* {t(menuItem.title)} */}
                  {menuItem.title}
                </Link>
              )
            )}
            {/* eslint-enable @typescript-eslint/no-unused-expressions */}
          </div>
          {/* This code is for text internationalisation with i18n */}
          {/* Uncomment the below code to get the language selection option */}
          {/* <Select variant="sz"
          onChange={(e) => handleChangeLanguage(e.target.value)}>
          <option value="en">English</option>
          <option value="fr">French</option>
        </Select> */}
        </nav>

        {/* Kebab menu button on mobile - open drawer */}
        <div className="md:hidden flex">
          <div
            className={`tham tham-w-6 w-full justify-end mtn-grey-800 ${active ? 'tham-active' : ''}`}
            onClick={() => setActive(!active)}
          >
            <div className="tham-box">
              <div className={`tham-inner ${inverted ? 'bg-white' : 'bg-mtn-blue-800'} `}/>
            </div>
          </div>
        </div>
      </header>

      {/* Drawer on mobile */}
      <nav
        className={[
          'py-4 px-4 sm:px-20 md:hidden fixed min-h-screen w-full z-40 font-medium transform transition-all duration-500',
          `left-full ${active ? '-translate-x-full' : ''} ${inverted ? 'bg-mtn-blue-900' : 'bg-white'}`
        ].join(' ')}
      >
        {/* Drawer - Close Button */}
        <div className={`tham tham-w-6 w-full justify-end mtn-grey-800 ${active ? 'tham-active' : ''}`}>
          <div className="tham-box">
            <img className="w-4 mr-1" src={closeIcon} alt="close" onClick={() => setActive(!active)}/>
          </div>
        </div>

        {/* Drawer - Menu Items (all) */}
        <div className={`${transparent ? 'mt-20' : ''} flex flex-col px-8`}>
          {menuItems.map((menuItem, mId) =>
            menuItem.external ? (
              <a
                className={[
                  'hover:text-gm-green-500 border-gm-green-500 py-8 border-b w-full',
                  'text-center transition-colors duration-500'
                ].join(' ')}
                href={menuItem.url}
                key={`${mId}-${menuItem.title.replace(' ', '-')}-${menuItem.url}`}
                onClick={() => menuItem.onClick ? menuItem.onClick() : setActive(false)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* {t(menuItem.title)} */}
                {menuItem.title}
              </a>
            ) : (
              <Link
                className={[
                  'hover:text-gm-green-500 border-gm-green-500 py-8 border-b w-full',
                  'text-center transition-colors duration-500'
                ].join(' ')}
                to={menuItem.url}
                key={`${mId}-${menuItem.title.replace(' ', '-')}-${menuItem.url}`}
                onClick={() => menuItem.onClick ? menuItem.onClick() : setActive(false)}
              >
                {menuItem.title}
              </Link>
            )
          )}
        </div>
      </nav>
    </>
  )
}

export default Header
