import { type FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from '@lib/hooks'
import { PaymentFormTestId } from '@lib/testing'
import {
  FRCreateStep,
  frStepPageTitles,
  getNextStep,
  EnterFRLocation,
  EnterFRCharity,
  EnterFRTargetAmount,
  SelectFRCoverPhoto,
  EnterFRTitle,
  SelectFRTeamMember,
  ConfirmNewFR,
  CreateFundraiserNext,
  AUTH_REQUIRED_STEPS,
  getPreviousStep,
  useCreateFundraiser,
  CREATE_FUNDRAISER_RESTART_QUERY,
  getInitialStepFromDraft,
  tempFundraiserImagePath
} from '~/components/fundraiser/create'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { LoginAndCreateAccount } from '~/components/authentication/LoginAndCreateAccount'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'
import { Tracking } from '~/service/tracking'
import { PreviewFundraiser } from './PreviewFundraiser'
import { Navigation } from '~/service/navigation'

const stepTracking = (step: FRCreateStep, fundraiser: FundraiserPlan): void => {
  switch (step) {
    case FRCreateStep.Location:
      Tracking.locationSelection(fundraiser.country, fundraiser.postcode)
      break
    case FRCreateStep.Charity:
      Tracking.pickCharity(fundraiser, fundraiser.charity ? fundraiser.charity.name : '')
      break
    case FRCreateStep.TargetAmount:
      Tracking.fundraisingGoal(fundraiser, fundraiser.target_amount)
      break
    case FRCreateStep.CoverPhoto:
      Tracking.addCoverImage(fundraiser)
      break
    case FRCreateStep.Title:
      Tracking.addFundraiserDetails(fundraiser, fundraiser.title ?? '', fundraiser.story ?? '')
      break
    default:
      break
  }
}

export const CreateFundraiserRedirect: FC = () => {
  const navigate = useNavigate()
  useEffect(() => navigate(Navigation.fundraiserCreate(), { replace: true }))
  return null
}

export const CreateFundraiser: FC = () => {
  const [step, setStep] = useState(FRCreateStep.Location)
  const [showPreview, setShowPreview] = useState(false)
  const [actioned, setActioned] = useState(false)
  const requiresLogin = useMemo(() => AUTH_REQUIRED_STEPS.includes(step), [step])
  const previousStep = useMemo(() => getPreviousStep(step), [step])
  const [searchParams, setSearchParams] = useSearchParams()
  const restart = !!searchParams.get(CREATE_FUNDRAISER_RESTART_QUERY)
  const { user } = useAuth()
  const {
    fundraiser,
    updateFundraiser,
    publishFundraiser,
    publishedId,
    error,
    processing
  } = useCreateFundraiser(user, !restart && !actioned)
  const navigate = useNavigate()

  useEffect(() => {
    if (!restart && !actioned) {
      const initial = getInitialStepFromDraft(fundraiser)
      setStep(initial)
    }
  }, [fundraiser, restart, actioned])

  const continueSteps = (partial: Partial<FundraiserPlan>): void => {
    if (fundraiser.published_time) {
      setStep(FRCreateStep.NextSteps)
      return
    }
    if (!actioned) {
      setActioned(true)
    }
    if (restart) {
      setSearchParams('', { replace: true })
    }
    stepTracking(
      step,
      {
        ...fundraiser,
        ...partial,
        coverPhoto: fundraiser.coverPhoto
      }
    )
    const next = getNextStep(step)
    if (next && next !== FRCreateStep.NextSteps) {
      updateFundraiser({ ...fundraiser, ...partial }).then(() => setStep(next))
        .catch(() => {})
      return
    }
    if (next === FRCreateStep.NextSteps) {
      publishFundraiser({ ...fundraiser, ...partial }).then(() => setStep(next))
        .catch(() => {})
    }
  }

  const triggerPreview = (partial: Partial<FundraiserPlan>): void => {
    if (!actioned) {
      setActioned(true)
    }
    Tracking.fundraiserPreview(fundraiser, partial.title ?? '', partial.story ?? '')
    updateFundraiser({ ...fundraiser, ...partial }).catch(() => {})
    setShowPreview(true)
  }

  if (requiresLogin && !user) {
    return (
      <LoginAndCreateAccount
        track={{
          createAccount: (fundraiserTips) => {
            Tracking.createFundraiserAccount(fundraiser, fundraiserTips)
          },
          verifyAccount: () => {
            Tracking.verifyFundraiserAccount(fundraiser)
          }
        }}
      />
    )
  }

  if (showPreview) {
    return <PreviewFundraiser fundraiserPlan={fundraiser} onDone={() => setShowPreview(false)} />
  }

  return (
    <FundraiserLayout
      title={frStepPageTitles[step]}
      hideBack={step === FRCreateStep.NextSteps}
      onBack={() => {
        if (!previousStep) {
          navigate(-1)
          return
        }
        setStep(previousStep)
      }}
    >
      {(() => {
        switch (step) {
          case FRCreateStep.Location:
            return (
              <EnterFRLocation
                processing={processing}
                partial={{
                  country: fundraiser.country,
                  postcode: fundraiser.postcode
                }}
                next={(args) => continueSteps(args)}
              />
            )
          case FRCreateStep.Charity:
            return (
              <EnterFRCharity
                processing={processing}
                partial={{
                  country: fundraiser.country,
                  charity: fundraiser.charity
                }}
                next={(args) => continueSteps(args)}
              />
            )
          case FRCreateStep.TargetAmount:
            return (
              <EnterFRTargetAmount
                processing={processing}
                partial={{
                  charity: fundraiser.charity,
                  target_amount: fundraiser.target_amount
                }}
                next={(args) => continueSteps(args)}
              />
            )
          case FRCreateStep.CoverPhoto:
            return (
              <SelectFRCoverPhoto
                processing={processing}
                partial={{
                  coverPhoto:
                    fundraiser.coverPhoto ||
                    tempFundraiserImagePath(fundraiser.fundraiser_id ?? ''),
                  youtubeUrl: fundraiser.youtubeUrl
                }}
                next={(args) => continueSteps({
                  ...args,
                  new_image_upload: !!args.coverPhoto && args.coverPhoto.includes('base64')
                })}
              />
            )
          case FRCreateStep.Title:
            return (
              <EnterFRTitle
                processing={processing}
                partial={{
                  charity: fundraiser.charity,
                  title: fundraiser.title,
                  story: fundraiser.story
                }}
                next={(args) => continueSteps(args)}
                showPreview={(args) => triggerPreview(args)}
              />
            )
          case FRCreateStep.TeamManage:
            return (
              <SelectFRTeamMember
                processing={processing}
                partial={{
                  teamMembers: fundraiser.teamMembers
                }}
                next={(args) => continueSteps(args)}
              />
            )
          case FRCreateStep.Confirm:
            return (
              <ConfirmNewFR
                processing={processing}
                partial={{
                  charity: fundraiser.charity,
                  charity_opt_in: fundraiser.charity_opt_in,
                  country: fundraiser.country
                }}
                next={(args) => continueSteps(args)}
              />
            )
          case FRCreateStep.NextSteps:
            return (
              <CreateFundraiserNext
                fundraiserId={publishedId}
              />
            )
          default: return <></>
        }
      })()}

      {!!error && (
        <p
          data-test-id={PaymentFormTestId.errorText}
          className="p-4 mt-2 text-sm text-right text-gm-red"
        >
          {error}
        </p>
      )}
    </FundraiserLayout>
  )
}
